import { getFileOptionsAuth } from "@/api/member";
import { useNotVipTips } from "./notVipTips";
import { ElMessage } from "element-plus";
import { tr } from "element-plus/es/locale";
import { useUser } from "@/composable/useUser";

/**
 * @param {string} authField 需要验证的字段
 * 文件设置按钮  setbutton
 * 复制链接按钮  cobybutton
 * 文件重命名按钮   renamebutton
 * 文件替换按钮   replacebutton
 * 文件预览按钮   previewbutton
 * 文件下架提示按钮   hintbutton
 * 删除  remote_destruction
 * 文件上下架  unmountbutton
 * 
 * 打开次数设置	open_times  --
 * 加密设置	encryption  --
 * 访客查看提醒	visitor_reminder
 * 查看访客轨迹	visitor_trajectory  --
 * 访问人数设置	visitors  --
 * 阅读时长设置	reading_duration  --
 * 有效期设置	validity_times  --
 * 极速上传下载	quickly_upload
 * 大文件上传（上限100m）	large_file
 * 批量上传文件	batch_upload
 * 文件搜索	file_search  --
 * 远程销毁	remote_destruction  --
 * 批量分享	batch_sharing
 * 无限上传次数	uploads_num
 * 无限上传文件页数	pages_num
 * 回收站功能	recycle  --
 * 访客位置获取	address --
 * 设置修改	setbutton  --
 * 复制链接	cobybutton  --
 * 文件重命名	renamebutton  --
 * 文件替换	replacebutton  --
 * 文件预览	previewbutton  --
 * 下架提示设置	hintbutton  --
 * 文件上下架	unmountbutton  --
 * 去插屏广告	guanggao
 * 去banner广告	guanggao_banner
 * 加水印	shuiyin  --
 * 营销表单	from  --
 * 营销二维码	qrcode  --
 * 品牌设置	pinpai  --
 * 自定义小程序封面	xiaochengxu  --
 * 文件打开验证	filelook  --
 * 打印设置	dayin  --
 * 下载设置	xiazai  --
 * 手机截图限制（开发中）	jietu_phone
 * 新增文件子链	add_fileurl  --
 * 访客白名单设置	baimingdan  --
 * 子账号管理	child_user  --
 * 企业文档管理	company_word
 */

// 权限集合
const authOptions = [
  { name: "打开次数设置", key: "open_times", value: 1 },
  { name: "加密设置", key: "encryption", value: 1 },
  { name: "访客查看提醒", key: "visitor_reminder", value: 1 },
  { name: "查看访客轨迹", key: "visitor_trajectory", value: 3 },
  { name: "访问人数设置", key: "visitors", value: 1 },
  { name: "阅读时长设置", key: "reading_duration", value: 1 },
  { name: "有效期设置", key: "validity_times", value: 3 },
  { name: "极速上传下载", key: "quickly_upload", value: 3 },
  { name: "大文件上传（上限100m）", key: "large_file", value: 3 },
  { name: "批量上传文件", key: "batch_upload", value: 3 },
  { name: "文件搜索", key: "file_search", value: 1 },
  { name: "远程销毁", key: "remote_destruction", value: 3 },
  { name: "批量分享", key: "batch_sharing", value: 3 },
  { name: "无限上传次数", key: "uploads_num", value: 3 },
  { name: "无限上传文件页数", key: "pages_num", value: 3 },
  { name: "回收站功能", key: "recycle", value: 3 },
  { name: "访客位置获取", key: "address", value: 3 },
  { name: "设置修改", key: "setbutton", value: 3 },
  { name: "复制链接", key: "cobybutton", value: 1 },
  { name: "文件重命名", key: "renamebutton", value: 3 },
  { name: "文件替换", key: "replacebutton", value: 3 },
  { name: "文件预览", key: "previewbutton", value: 1 },
  { name: "下架提示设置", key: "hintbutton", value: 3 },
  { name: "文件上下架", key: "unmountbutton", value: 3 },
  { name: "去插屏广告", key: "guanggao", value: 3 },
  { name: "去banner广告", key: "guanggao_banner", value: 3 },
  { name: "加水印", key: "shuiyin", value: 3 },
  { name: "营销表单", key: "from", value: 3 },
  { name: "营销二维码", key: "qrcode", value: 3 },
  { name: "品牌设置", key: "pinpai", value: 2 },
  { name: "自定义小程序封面", key: "xiaochengxu", value: 3 },
  { name: "文件打开验证", key: "filelook", value: 3 },
  { name: "打印设置", key: "dayin", value: 3 },
  { name: "下载设置", key: "xiazai", value: 3 },
  { name: "手机截图限制（开发中）", key: "jietu_phone", value: 3 },
  { name: "新增文件子链", key: "add_fileurl", value: 3 },
  { name: "访客白名单设置", key: "baimingdan", value: 3 },
  { name: "子账号管理", key: "child_user", value: 2 },
  { name: "企业文档管理", key: "company_word", value: 2 }
];

// 不用处理的权限集合
const noNeedHandleAuth = authOptions.filter(item => item.value === 1).map(item => item.key);
// 需要处理的权限集合
const needHandleAuth = authOptions.filter(item => item.value === 3).map(item => item.key);
// 企业会员的权限集合
const enterpriseMemberAuth = authOptions.filter(item => item.value === 2).map(item => item.key);

export const useCheckFileOptionsAuth = () => {
  const notVipTipsInfo = useNotVipTips();
  const { notVipTips } = notVipTipsInfo;

  const checkFileOptionsAuth = async (authField: string) => {
    const { userInfo, userType } = useUser();
    return new Promise(async (resolve, reject) => {
      if (!userInfo.value.token || noNeedHandleAuth.includes(authField)) return resolve(true)
      if (enterpriseMemberAuth.includes(authField)) {
        if (userType.value === 'qy_vip' || userType.value === 'sub_account') return resolve(true)
        else {
          notVipTips('qy')
          return reject()
        }
      } else if (needHandleAuth.includes(authField)) {
        try {
          const {code, zuidi}:any = await getFileOptionsAuth({ type: authField });
          switch (code) {
            case 1:
              return resolve(true)
            case 400:
            case 405:
              notVipTips(zuidi)
              return reject('无权限')
          }
        } catch (error) {
          console.log(error);
          return reject('无权限-查询权限失败')
        }
      }
      return resolve(true)
    })
  }

  return { checkFileOptionsAuth };
}
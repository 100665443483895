<template>
  <div class="customize-content">
    <!-- <h5>品牌定制介绍：</h5>
    <ul class="customize">
      <li v-for="(item, index) in customize" :key="index">
        {{ item }}
      </li>
    </ul> -->
    <h5>私有化部署：</h5>
    <p>
      将笔熊分享应用部署到您自己的服务器上。域名、品牌、logo、广告语您都可以自定义。私有化版本为企业定制化版本，供企业内部使用，部分功能有所差异。
    </p>

    <div class="contact flex-a-center" @click="toKf">
      <span>如需品牌定制或私有化部署请联系客服</span>
      <img src="@/assets/header/u008.png" alt="">
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';
// 品牌定制介绍
const customize = ref<string[]>([
  '可以为您自定义分享文档的标题后缀',
  '可以为您定制分享文档的专属广告语',
  '可以为您定制分享文档的logo'
]);

const toKf = () => {
  window.open('https://work.weixin.qq.com/kfid/kfc4a7552f98cdf9983', '_blank')
}
</script>

<style lang="scss" scoped>
h5 {
    font-size: 0.22rem;
    color: #353535;

    &:not(:first-of-type) {
      margin-top: 0.34rem;
    }
  }

  .customize {
    padding-left: 0.4rem;
    margin-top: 0.33rem;

    li {
      font-size: 0.19rem;
      color: #3F3F3F;
      line-height: 1.8;

      &::marker {
        background: #353535;
        width: 0.07rem;
        height: 0.07rem;
      }
    }
  }

  p {
    font-size: 0.18rem;
    color: #353535;
    line-height: 0.3rem;
    margin-top: 0.25rem;
    padding-left: 0.13rem;
  }

  .contact{
    margin-top: 0.48rem;
    font-size: 0.22rem;
    color: #FE0000;
    cursor: pointer;
    img{
      margin-left: 0.08rem;
      cursor: pointer;
      width: 0.23rem;
    }
  }
  @media screen and (max-width: 768px) {
    .customize-content{
      max-height: 100%;
      overflow: auto;
      padding: 0 0.44rem;
    }
    h5{
      font-size: 0.36rem;
      color: #353535;
    }
    .customize{
      li{
        font-size: 0.3rem;
        color: #3F3F3F;
        line-height: 1.5;
      }
    }
    p{
      font-size: 0.3rem;
      color: #353535;
      line-height: 1.5;
    }
    .contact{
      margin-top: 0.53rem;
      font-size: 0.3rem;
      color: #E2271B;
      img{
        margin-left: 0.08rem;
        cursor: pointer;
        width: 0.32rem;
      }
    }
  }
</style>
<template>
  <div class="form-container p32 flex-y h100">
    <div class="flex-c-between mb10">
      <div>
        <p-button text="线索下载" icon="member/u008.png" @click="handleDownload" />
      </div>
      <div class="flex-a-center">
        <el-date-picker
          v-model="time"
          type="datetimerange"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          placeholder="请选择日期"
          format="YYYY-MM-DD HH:mm:ss"
          value-format="YYYY-MM-DD HH:mm:ss"
          class="mr10"
        />
        <table-search v-model:value="keywords" @search="toSearch" />
      </div>
    </div>
    <p-table ref="pTableRef" v-bind="tableConfig">
      <template #remark="{ row }">
        <div class="flex-c-center">
          <el-tooltip  v-if="row.beizhu" effect="dark" :content="row.beizhu" placement="top" >
            <span class="text-ellipsis-1">{{ row.beizhu }}</span>
          </el-tooltip>
          <p-button icon="member/u005.png" theme="" @click="openSetRemarkDialog(row)" />
        </div>
      </template>
    </p-table>
    <SetRemark ref="setRemarkRef" @success="handleRemarkSuccess" />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, defineAsyncComponent } from 'vue'
import { getClueListApi } from '@/api/other'
import { ElMessage } from 'element-plus'
import * as XLSX from 'xlsx'

// 引入组件
const TableSearch = defineAsyncComponent(() => import('@/components/PTable/TableSearch.vue'))
const PTable = defineAsyncComponent(() => import('@/components/PTable/index.vue'))
const SetRemark = defineAsyncComponent(() => import('./components/SetRemark.vue'))

const keywords = ref('')
const time = ref('')

const tableConfig = reactive({
  api: getClueListApi,
  showOperation: false,
  columns: [
    { prop: 'file_name', label: '文档名称' },
    { prop: 'from_name', label: '表单名称' },
    { prop: 'createtime', label: '留咨时间' },
    { prop: 'name', label: '姓名', width: 100 },
    { prop: 'phone', label: '电话' },
    { prop: 'wx', label: '微信' },
    { prop: 'qq', label: 'QQ' },
    { prop: 'email', label: '邮箱' },
    { prop: 'company', label: '公司' },
    { prop: 'position', label: '职位' },
    { prop: 'message', label: '留言' },
    { prop: 'remark', label: '备注', type: 'slot', width: 100 },
  ],
  params: {
    file_name: keywords.value,
    date: time.value
  },
  data: []
})

// 搜索
const toSearch = () => {
  console.log('搜索', keywords.value, time.value)
  tableConfig.params.date = time.value
  tableConfig.params.file_name = keywords.value
}

const setRemarkRef = ref()

const openSetRemarkDialog = (row: any) => {
  setRemarkRef.value.openDialog({ id: row.id, beizhu: row.beizhu })
}

const pTableRef = ref()
const handleRemarkSuccess = (remark: string) => {
  // 处理备注设置成功后的逻辑，例如刷新列表等
  console.log('备注设置成功:', remark)
  pTableRef.value.refresh()
}

// 线索下载
const handleDownload = async () => {
  try {
    const res = pTableRef.value.tableData
    if (res.length === 0) {
      ElMessage.error('没有数据可以下载')
      return
    }

    // 定义字段映射
    const fieldMapping = {
      file_name: '文档名称',
      from_name: '表单名称',
      createtime: '留咨时间',
      name: '姓名',
      phone: '电话',
      wx: '微信',
      qq: 'QQ',
      email: '邮箱',
      company: '公司',
      position: '职位',
      message: '留言',
      beizhu: '备注'
    }

    // 转换数据
    const convertedData = res.map((item: any) => {
      const newItem: any = {}
      for (const key in fieldMapping) {
        if (key in item) {
          newItem[fieldMapping[key as keyof typeof fieldMapping]] = item[key]
        }
      }
      return newItem
    })

    // 创建一个工作簿
    const wb = XLSX.utils.book_new()
    
    // 将JSON数据转换为工作表
    const ws = XLSX.utils.json_to_sheet(convertedData)
    
    // 设置单元格自适应宽度
    const wscols = Object.keys(fieldMapping).map(key => {
      const maxLength = Math.max(
        fieldMapping[key as keyof typeof fieldMapping].length,
        ...convertedData.map((item: any) => String(item[fieldMapping[key as keyof typeof fieldMapping]] || '').length)
      )
      return { wch: maxLength + 4 } // 添加4个字符的额外宽度作为缓冲
    })
    ws['!cols'] = wscols
    
    // 将工作表添加到工作簿
    XLSX.utils.book_append_sheet(wb, ws, "线索数据")
    
    // 生成二进制字符串
    const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' })
    
    // 将二进制字符串转换为Blob对象
    const blob = new Blob([s2ab(wbout)], { type: "application/octet-stream" })
    
    // 创建下载链接
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob)
    downloadElement.href = href
    // downloadElement.download = `线索列表_${new Date().toISOString().split('T')[0]}.xlsx` // 设置下载文件名
    downloadElement.download = `线索列表.xlsx` // 设置下载文件名
    document.body.appendChild(downloadElement)
    downloadElement.click()
    document.body.removeChild(downloadElement)
    window.URL.revokeObjectURL(href)
    // ElMessage.success('下载成功')
  } catch (error) {
    console.error('下载失败', error)
    ElMessage.error('下载失败，请稍后重试')
  }
}

// 辅助函数：将字符串转换为ArrayBuffer
function s2ab(s: string) {
  const buf = new ArrayBuffer(s.length)
  const view = new Uint8Array(buf)
  for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF
  return buf
}

</script>

<style scoped lang="scss">
</style>
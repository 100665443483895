
/**
 * 格式化日期
 */
export const formatTimeSelf = (d: any, t: any = 'yyyy-MM-dd hh:mm:ss') => {
    d = d.replace(/\-/g, '/');
    let date = new Date(d);
    let o = {
        "M+" : date.getMonth()+1,                 //月份
        "d+" : date.getDate(),                    //日
        "h+" : date.getHours(),                   //小时
        "m+" : date.getMinutes(),                 //分
        "s+" : date.getSeconds(),                 //秒
        "q+" : Math.floor((date.getMonth()+3)/3), //季度
        "S"  : date.getMilliseconds()             //毫秒
    };
    if(/(y+)/.test(t)){
        t=t.replace(RegExp.$1,(date.getFullYear()+"").substr(4-RegExp.$1.length));
    };
    for(let k in o){
        if(new RegExp("("+ k +")").test(t)){
            // @ts-ignore
            t=t.replace(RegExp.$1,(RegExp.$1.length==1)?(o[k]):(("00"+ o[k]).substr((""+o[k]).length)));
        };
    }
    return t;
};

<template>
  <div class="p-tabs flex-a-center">
    <div v-for="(tab, index) in tabs" :key="index" @click="tabChange(index, tab)"
      :class="['tab', 'flex-a-center', 'cursor-pointer', {'active': current == index }]">
      {{ tab.name }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ElMessage } from 'element-plus'

const props = defineProps({
  tabs: {
    type: Array as any,
    default: () => []
  },
  current: {
    type: Number,
    default: 0
  },
  loadingData: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['tabChange', 'update:current'])

// 选项卡切换
const tabChange = (index: number, item: any) => {
  if (props.loadingData) return ElMessage.warning('数据加载中，请稍后再试')
  emit('tabChange', {index, item})
  emit('update:current', index)
}

</script>

<style scoped lang="scss">
.p-tabs {
  height: 0.3rem;
  background: #EEEFF0;
  border-radius: 0.15rem;
  padding: 0 0.03rem;
  .tab {
    padding: 0 0.2rem;
    font-size: 0.15rem;
    color: #495057;
    background-color: transparent;
    transition: all 0.3s ease-in-out;
    &.active {
      background-color: #fff;
      height: 0.26rem;
      border-radius: 0.15rem;
      color: #000000;
    }
  }
}
</style>
import request from '@/utils/request';

// 获取会员套餐 参数 leix 会员类别 1个人会员 2企业会员
export const getPackageApi = (type: number) => {
  return request({
    url: '/vip/lst',
    method: 'get',
    params: { leix: type }
  });
};

// 获取会员权益
export const getVipEquityApi = () => {
  return request({
    url: '/Information/userEquity',
    method: 'get'
  });
};

// 查询最后一次企业会员订单
export const getLastOrderApi = () => {
  return request({
    url: '/vip/lastqyorder',
    method: 'get'
  });
};

// 流量充值  num购买流量大小  paytype支付类型 默认wx wx ali
export const flowRechargeApi = (data: any) => {
  return request({
    url: '/vip/buyliul',
    method: 'post',
    data
  });
};

// 扩容子账号  num扩容人数  order_id最新企业会员订单id  paytype支付类型 默认wx wx ali
export const expandApi = (data: any) => {
  return request({
    url: '/vip/kuorong',
    method: 'post',
    data
  });
};

// 流量统计
export const flowStatisticsApi = () => {
  return request({
    url: '/liul/info',
    method: 'get'
  });
};

// 不同会员权益
export const differentVipEquityApi = (params: any) => {
  return request({
    url: '/equity/newequity',
    method: 'get',
    params
  });
};
<template>
  <div id="file" class="h100">
    <div v-if="isPC" class="form-view h100 flex-y">
      <!-- 目录 -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item v-for="(item, index) in fileCatalogue" :key="index">
          <span :class="['breadcrumb-item', { last: index === fileCatalogue.length - 1 }]" @click="toFolder(item)">{{ item.name }}</span>
        </el-breadcrumb-item>
      </el-breadcrumb>
      <div class="table-top flex-c-between pb10 mt16">
        <div class="flex-a-center">
          <template v-if="showDelete">
            <p-button class="mr10" text="删除" theme="info" @click="deleteFile" />
            <template v-if="showSet">
              <p-button class="mr10" text="加密设置" theme="info" @click="setFile" />
              <p-button class="mr10 copy-btn-all" text="复制链接" theme="info" @click="batchCopy" :data-clipboard-text="linkStr" />
            </template>
            <p-button text="转移" theme="info" @click="transferFile" />
          </template>
          <template v-else>
            <p-button class="mr10" text="上传" icon="member/u003.png" @click="uploadFile" />
            <p-button text="新建文件夹" icon="member/u004.png" theme="minor" @click="createFolder" />
          </template>
        </div>
        <div class="flex-a-center">
          <user-search v-model:value="fileListConfig.params.up_user_id" @submit="toSearch" />
          <table-search v-model:value="fileListConfig.params.title" @search="toSearch" />
        </div>
      </div>
      <!-- 表格 -->
      <file-list ref="fileListRef" class="flex-auto" v-bind="fileListConfig" v-model:data="tableData" @enterFolder="enterFolder" @listCheck="listCheck" />
    </div>
    <phone-file v-else v-bind="fileListConfig" />
    <!-- 创建文件夹 -->
    <create-folder-view ref="createFolderRef" @success="getFileList"></create-folder-view>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, defineAsyncComponent, onMounted, computed } from 'vue'
import { useRouter } from 'vue-router'
import checkPc from '@/utils/checkPc'
import { useCheckFileOptionsAuth } from '@/utils/checkFileOptionsAuth'
import { getFileListApi } from '@/api/file/new'
import { useFileItem } from '@/views/member/file/components/phone/hooks/file';

const { copyText } = useFileItem()

const router = useRouter()
/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const TableSearch = defineAsyncComponent(() => import('@/components/PTable/TableSearch.vue'))
const FileList = defineAsyncComponent(() => import("@/views/member/file/components/FileList.vue"));
const PhoneFile = defineAsyncComponent(() => import("@/views/member/file/components/phone/PhoneFile.vue"));
const createFolderView = defineAsyncComponent(() => import("@/views/member/file/components/CreateFolder.vue"));
const UserSearch = defineAsyncComponent(() => import("@/components/PTable/UserSearch.vue"));

const { checkFileOptionsAuth } = useCheckFileOptionsAuth();

/* fileList 组件所需参数 */
const fileListRef = ref()
const fileListConfig = reactive({
  api: getFileListApi,
  params: { title: '', up_user_id: '' }
})

// 文件目录列表
const fileCatalogue = ref([
  { name: '全部文件', id: 0 },
])
// 当前操作的文件夹
const currentFolder = computed(() => fileCatalogue.value[fileCatalogue.value.length - 1])
// 进入文件夹
const enterFolder = (item: any) => {
  const index = fileCatalogue.value.findIndex((i: any) => i.id === item.id)
  if (index > -1) fileCatalogue.value.splice(index + 1)
  else fileCatalogue.value.push({ name: item.title, id: item.id })
}
// 切换文件夹
const toFolder = (item: any) => {
  fileListRef.value.enterFolder(item)
}

/* 新增 */
// 上传
const uploadFile = () => {
  router.push({ path: '/pdf/index', query: { folder_id: currentFolder.value.id } })
}

// 新建文件夹
const createFolderRef:any = ref(null)
const createFolder = async () => {
  let pid = currentFolder.value.id
  createFolderRef.value.openDialog({ pid })
}

// 数据列表多选
const multipleSelection = ref([])
const listCheck = (list: any) => {
  multipleSelection.value = list
}
const showDelete = computed(() => multipleSelection.value.length > 0)
const showSet = computed(() => multipleSelection.value.length > 0 && multipleSelection.value.every((i: any) => i.file_type === 1 && i.child_num == 0))

/* 表格数据操作 */
// 删除
const deleteFile = async () => {
  await checkFileOptionsAuth('remote_destruction')
  fileListRef.value.delBtn()
}

// 加密设置
const setFile = async () => {
  await checkFileOptionsAuth('setbutton')
  fileListRef.value.setBtn()
}

// 批量复制链接
const linkStr = computed(() => {
  return multipleSelection.value.map((link: any) => link.copy).join('\n');
});
const batchCopy = async () => {
  await checkFileOptionsAuth('batch_sharing')
  copyText(linkStr.value)
}

// 转移
const transferFile = async () => {
  const ids = multipleSelection.value.map((i: any) => i.id)
  fileListRef.value.folderTransfer(ids)
}

const tableParams = ref(fileListConfig.params)
const tableData = ref([])

const getFileList = async () => {
  fileListRef.value.refresh()
}

/**
 * 非会员操作提示
 */
const toSearch = async () => {
  await checkFileOptionsAuth('file_search')
  fileListRef.value.refresh()
}

onMounted(() => {
  // getFileList()
})
</script>

<style lang="scss" scoped>
:deep(.el-form--inline) {
  .el-form-item {
    margin-bottom: 0;
    margin-right: 0.1rem;

    .el-input__wrapper {
      background-color: transparent;
    }
  }
}

.table-top {
  :deep(.el-button) {
    color: #fff;
  }
}

.table-box {
  :deep(.el-button) {
    // color: #fff;
    border-color: #dfdfdf;
  }
}

// 面包屑
.breadcrumb-item {
  font-weight: 400;
  font-size: 0.15rem;
  color: #FF7B6D;
  cursor: pointer;
  &.last {
    color: #1D1D1D;
    cursor: default;
  }
}

@media screen and (max-width: 768px) {
  #file {
    height: 100%;
  }
}
</style>

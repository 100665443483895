<template>
  <div class="white-list-container p32 flex-y h100">
    <div class="flex-c-between mb10">
      <div>
        <p-button text="新建白名单组" @click="openCreateWhiteListDialog" />
      </div>
      <div class="flex-a-center">
        <!-- <table-search v-model:value="keywords" @search="toSearch" /> -->
      </div>
    </div>
    <p-table ref="pTableRef" v-bind="tableConfig" @edit="handleEdit">
      <!-- 可以添加自定义列模板，如果需要的话 -->
    </p-table>
    <CreateWhiteList ref="createWhiteListRef" @success="handleCreateSuccess" />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, defineAsyncComponent } from 'vue'
import { getWhiteListApi, deleteWhiteListApi } from '@/api/other'

// 引入组件
const TableSearch = defineAsyncComponent(() => import('@/components/PTable/TableSearch.vue'))
const PTable = defineAsyncComponent(() => import('@/components/PTable/index.vue'))
const CreateWhiteList = defineAsyncComponent(() => import('./components/CreateWhiteList.vue'))

const keywords = ref('')

const tableConfig = reactive({
  api: getWhiteListApi,
  delApi: deleteWhiteListApi,
  columns: [
    { prop: 'title', label: '白名单名称' },
    { prop: 'status', label: '状态', type: 'status' },
    { prop: 'fw', label: '生效范围' },
  ],
  // params: { name: keywords.value },
  data: []
})

// 搜索
const toSearch = () => {
  console.log('搜索', keywords.value)
  // tableConfig.params.name = keywords.value
}

const createWhiteListRef = ref()

const openCreateWhiteListDialog = () => {
  createWhiteListRef.value.openDialog()
}

const pTableRef = ref()
const handleCreateSuccess = () => {
  // 处理白名单创建成功后的逻辑，刷新列表
  console.log('白名单组创建成功')
  pTableRef.value.refresh()
}

const handleEdit = (row: any) => {
  console.log('编辑', row)
  createWhiteListRef.value.openDialog(row)
}

</script>

<style scoped lang="scss">
// 可以添加需要的样式
</style>
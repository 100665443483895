<template>
  <div class="brand-container h100 p24">
    <div class="brand-wrap flex-y h100 p24">
      <div class="flex-c-center">
        <el-steps style="width: 80%" :active="setup" align-center>
          <el-step v-for="(item, index) in setupList" :key="index" :title="item.title" description="">
            <template #icon>
              <img class="setup-icon" :src="icons[item.status]" alt="" srcset="">
            </template>
            <template #title>
              <span class="setup-title font15" :style="{ color: item.color }">{{ item.title }}</span>
            </template>
          </el-step>
        </el-steps>
      </div>
      <brand-form ref="brandFormRef" v-model:data="brandInfo" @success="getBrandInfo">
        <!-- 拒绝原因 -->
        <div class="reason-wrap flex-a-center" v-if="brandInfo.pdf_status === 3">
          <div class="reason-title">拒绝原因：</div>
          <div class="reason-content">{{ brandInfo.pdf_jj }}</div>
        </div>
      </brand-form>
      <!-- 底部按钮 -->
      <div v-if="setup === 0" class="flex-a-center mt10">
        <p-button text="确定" @click="submitForm" class="mr10" />
        <p-button text="取消" theme="cancel" />
      </div>
      <div v-else-if="brandInfo.pdf_status === 3 || brandInfo.pdf_status === 1" class="flex-a-center mt10">
        <p-button text="修改" @click="submitForm" class="mr10" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineAsyncComponent } from 'vue'
import { getBrandInfoApi } from '@/api/other'

const BrandForm = defineAsyncComponent(() => import('./components/BrandForm.vue'))

const setup = ref(0)
const icons:any = ref({
  'default': require('@/assets/member/other/default.png'),
  'info': require('@/assets/member/other/info.png'),
  'success': require('@/assets/member/other/success.png'),
  'warning': require('@/assets/member/other/warning.png'),
  'error': require('@/assets/member/other/error.png')
})

const statusObj:any = {
  1: {
    setup: 3,
    list: [
      { title: '资质提交', status: 'info', color: '#B6B8BA' },
      { title: '审核', status: 'info', color: '#B6B8BA' },
      { title: '上线', status: 'success', color: '#B6B8BA' }
    ]
  },
  2: {
    setup: 2,
    list: [
      { title: '资质提交', status: 'info', color: '#B6B8BA' },
      { title: '审核中', status: 'warning', color: '#FABE00' },
      { title: '上线', status: 'info', color: '#B6B8BA' }
    ]
  },
  3: {
    setup: 2,
    list: [
      { title: '资质提交', status: 'info', color: '#B6B8BA' },
      { title: '审核拒绝', status: 'error', color: '#F02020' },
      { title: '上线', status: 'info', color: '#B6B8BA' }
    ]
  }
}

const setupList = ref([
  { title: '资质提交', status: 'default', color: '#B6B8BA' },
  { title: '审核', status: 'info', color: '#B6B8BA' },
  { title: '上线', status: 'info', color: '#B6B8BA' }
])

const brandFormRef = ref()
// 提交表单
const submitForm = () => {
  brandFormRef.value.handleConfirm()
}

const brandInfo:any = ref({})
// 获取品牌信息  审核状态 1上线 2审核中 3已拒绝
const getBrandInfo = async () => {
  const res:any = await getBrandInfoApi()
  console.log(res, '获取品牌信息')
  brandInfo.value = res
  if (res.pdf_status) {
    setupList.value = statusObj[res.pdf_status].list
    setup.value = statusObj[res.pdf_status].setup
    console.log(setupList.value, 'setupList.value')
  }
}

onMounted(async () => {
  await getBrandInfo()
})

</script>

<style scoped lang="scss">
.brand-container {
  .brand-wrap {
    background: #FFFFFF;
    box-shadow: 0px 0px 7px 1px rgba(118,118,118,0.04);
    .setup-icon {
      width: 0.34rem;
    }
    :deep(.el-step) {
      .el-step__title {
        line-height: 0.24rem;
      }
      .is-finish, .is-process {
        color: #B6B8BA;
        border-color: #B6B8BA;
        font-weight: 500;
      }
    }
  }
  .reason-wrap {
    .reason-title {
      font-size: 0.14rem;
      color: #000000;
      width: 1.2rem;
    }
    .reason-content {
      font-size: 0.14rem;
      color: #8C8C8C;
    }
  }
}
</style>
<template>
  <div style="background-color: #fff;">
    <img v-if="isPC" class="banner" :src="banner || require('@/assets/about/banner.png')" alt="">
    <img v-else class="banner banner-m" :src="bannerM || require('@/assets/about/banner_m.png')" alt="">
    <div class="main-content pt30 pb100">
      <div class="main-content-box" v-html="mainContent"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import checkPc from '@/utils/checkPc'
import { singlePageApi } from '@/api/common'

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 定义变量
const banner = ref('')
const bannerM = ref('')
const mainContent = ref('')

// 获取单页内容
const getSinglePage = async () => {
  const res:any = await singlePageApi({id: 1})
  // console.log(res)
  banner.value = res.pic
  bannerM.value = res.pic_m
  mainContent.value = res.content
}

getSinglePage()

</script>

<style lang="scss" scoped>
.banner {
  height: 6rem;
  width: 100%;
  object-fit: cover;
  display: block;
  &-m {
    display: none;
  }
}
.main-content {
  font-size: .32rem;
  line-height: 1.8;
  color: #272626;
  background-color: #fff;
}
@media screen and (max-width: 768px) {
  .banner {
    display: none;
    &-m {
      display: block;
      height: 3.92rem;
    }
  }
  .main-content {
    padding: 0.6rem 0.5rem !important;
    font-size: 0.24rem;
  }
}
</style>
import request from '@/utils/request';

// 获取二维码列表
export const getQrCodeListApi = (data: any) => {
  return request({
    url: '/qrcode/lst',
    method: 'post',
    data
  });
}

// 新增、编辑二维码
export const addOrEditQrCodeApi = (data: any) => {
  return request({
    url: '/qrcode/add',
    method: 'post',
    data
  });
}

// 删除二维码
export const deleteQrCodeApi = (id: number) => {
  return request({
    url: '/qrcode/del',
    method: 'post',
    data: {
      id
    }
  });
}

// 获取表单列表
export const getFormListApi = (data: any) => {
  return request({
    url: '/from/lst',
    method: 'post',
    data
  });
}

// 新增、编辑表单
export const addOrEditFormApi = (data: any) => {
  return request({
    url: '/from/add',
    method: 'post',
    data
  });
}

// 删除表单
export const deleteFormApi = (id: number) => {
  return request({
    url: '/from/del',
    method: 'post',
    data: {
      id
    }
  });
}

/* 品牌相关 */
// 查询品牌信息
export const getBrandInfoApi = () => {
  return request({
    url: '/pingpai/info',
    method: 'get'
  });
}

// 新增、编辑品牌信息
export const addOrEditBrandApi = (data: any) => {
  return request({
    url: '/pingpai/edit',
    method: 'post',
    data
  });
}

/* 子账号管理 */
// 查询部门
export const getDepartmentListApi = (params: any) => {
  return request({
    url: '/company/selbumen',
    method: 'get',
    params
  });
}
// 添加部门
export const addDepartmentApi = (data: any) => {
  return request({
    url: '/company/addbumen',
    method: 'post',
    data
  });
}
// 重命名部门
export const renameDepartmentApi = (data: any) => {
  return request({
    url: '/company/editbumen',
    method: 'post',
    data
  });
}
// 删除部门
export const deleteDepartmentApi = (id: number) => {
  return request({
    url: '/company/delbumen',
    method: 'post',
    data: {
      id
    }
  });
}
// 员工列表 get
export const getEmployeeListApi = (params: any) => {
  return request({
    url: '/company/seluser',
    method: 'get',
    params
  });
}
// 添加、编辑员工
export const addOrEditEmployeeApi = (data: any) => {
  return request({
    url: '/company/adduser',
    method: 'post',
    data
  });
}
// 删除员工
export const deleteEmployeeApi = (id: number) => {
  return request({
    url: '/company/deluser',
    method: 'post',
    data: {
      id
    }
  });
}
// 获取权限列表
export const getPermissionListApi = () => {
  return request({
    url: '/company/selquanxian',
    method: 'get'
  });
}

/* 线索相关 */
// 获取线索列表
export const getClueListApi = (params: any) => {
  return request({
    url: '/from/selmsg',
    method: 'get',
    params
  });
}
// 线索备注
export const addOrEditClueApi = (data: any) => {
  return request({
    url: '/from/editmsg',
    method: 'post',
    data
  });
}

/* 白名单相关 */
// 获取白名单列表
export const getWhiteListApi = (params: any) => {
  return request({
    url: '/bmd/lst',
    method: 'get',
    params
  });
}
// 添加、编辑白名单
export const addOrEditWhiteListApi = (data: any) => {
  return request({
    url: '/bmd/add',
    method: 'post',
    data
  });
}
// 删除白名单
export const deleteWhiteListApi = (id: number) => {
  return request({
    url: '/bmd/del',
    method: 'post',
    data: {
      id
    }
  });
}
// 查询访客
export const getVisitorListApi = (params: any) => {
  return request({
    url: '/bmd/seluser',
    method: 'get',
    params
  });
}

// 获取组织架构带人 不分页
export const getUserListApi = (params: any) => {
  return request({
    url: '/company/selbumenuser',
    method: 'get',
    params
  });
}
import { computed, defineAsyncComponent, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useUserStoreHook } from "@/store/modules/user";
import { timeStamp2String } from '@/utils/common'
import { ElMessage, ElMessageBox } from 'element-plus'
import router from '@/router'
import { getLastOrderApi } from '@/api/member/new'
import { useCheckFileOptionsAuth } from "@/utils/checkFileOptionsAuth"

export function useUser() {

  // 导入组件
  const PayRenewDialog = defineAsyncComponent(() => import('@/components/Dialog/PayRenewDialog.vue'))
  const payRenewDialogRef = ref()

  const PayDilatationDialog = defineAsyncComponent(() => import('@/components/Dialog/PayDilatationDialog.vue'))
  const payDilatationDialogRef = ref()

  const userStore = useUserStoreHook()
  const { userInfo } = storeToRefs(userStore)

  const checkFileOptionsAuth = computed(() => useCheckFileOptionsAuth().checkFileOptionsAuth)

  // 校验权限
  const checkAuth = (vipAuth: boolean) => {
    return (vipAuth && userInfo.value.vip == 1) || !vipAuth;
  }

  // 校验权限 后台联动
  /**
   * 打开次数设置    open_times 1
   * 加密设置       encryption 1
   * 访客查看提醒    visitor_reminder
   * 查看访客轨迹    visitor_trajectory 1
   * 访问人数设置    visitors   1
   * 阅读时长设置    reading_duration  1
   * 有效期设置      validity_times  1
   * 极速上传下载    quickly_upload 1
   * 大文件上传      large_file 1
   * 批量上传文件    batch_upload 1
   * 文件搜索        file_search 1
   * 远程销毁        remote_destruction 1
   * 批量分享        batch_sharing  1
   * 无限上传次数    uploads_num 1
   * 无限上传文件页数    pages_num
   * 回收站功能          recycle 1
   * 
   * 加水印           shuiyin
   * 营销表单         from
   * 营销二维码       qrcode
   * 品牌定制         pinpai
   * 自定义小程序封面  xiaochengxu
   * 文件打开验证     filelook
   * 打印设置         dayin
   * 下载设置         xiazai
   * 手机截图限制     jietu_phone
   * 新增文件链接     add_fileurl
   * 访客白名单设置   baimingdan
   * 子账号管理       child_user
   * 企业文档管理     company_word
   * @param authStr  权限字符
   * @param needRefresh  更新用户信息
   */
  const checkAuthBack = async (authStr: string, needRefresh = false) => {
    if (needRefresh) {
      const res:any = await userStore.getUserInfo()
      let equity:any = res.equity_list
      // console.log(equity);
      return Promise.resolve(equity[authStr] == 1 ? true : false)
    } else {
      let user:any = userInfo.value || {}
      let equity:any = user.equity_list
      if (equity) return Promise.resolve(equity[authStr] == 1 ? true : false)
      else return Promise.resolve(false)
    }
  }

  /**
   * 处理会员显示文字
   * @param type
   * @returns
  */
  const handleShowText = (type:number) => {
    let user = userInfo.value
    let obj = { timeText: '未开通', vipText: '开通', vipType: '未开通', qy_log: '' }
    const { isCrown, text, timeKey } = userVip.value
    if (isCrown) {
      if (type == 1) obj.timeText = `您已开通 ${timeStamp2String(user[timeKey], 'Y-m-d h:i:s')} 到期`, obj.vipType = text
      if (type == 2) obj.timeText = `会员有效期: ${timeStamp2String(user[timeKey], 'Y-m-d h:i:s')}`
      if (userType.value == 'qy_vip' && user.qy_log) obj.qy_log = `（${user.qy_log}）`
      obj.vipText = '续费'
    } else {
      if (user.yxtime || user.qy_yxtime) {
        if (type == 1) obj.timeText = `您的会员已到期`, obj.vipType = '已到期'
        if (type == 2) obj.timeText = `您的会员已到期`
        obj.vipText = '续费'
      }
    }
    return obj
  }

  /**
   * 非会员操作会员功能提示
   * @param authStr 权限key
   * @param needRefresh 更新用户信息
   * @param tips 提示语
   * @returns {Promise}
  */
  const handleVipTip = async (authStr: string, needRefresh = false, tips = '') => {
    console.log(authStr)
    const isAuth = await checkAuthBack(authStr, needRefresh)
    console.log(isAuth, 'isAuth')
    if (!isAuth) {
      try {
        // 确认提示
        await ElMessageBox.confirm(`${tips ? tips : '此功能为会员功能，需要开通会员才能使用。'}`, '提示', {
          confirmButtonText: '去开通',
          cancelButtonText: '取消'
        })
        const route = router && router.currentRoute.value
        router.push(`/member/join?redirect=${route.path}&params=${JSON.stringify(route.query ? route.query : route.params)}`)
      } catch (error) {
        console.log(error)
        return Promise.resolve(false)
      }
    } else {
      return Promise.resolve(true)
    }
  }

  // 路由跳转
  const toPath = async (v: any, type = '') => {
    let authStr = v.meta?.authStr
    if (authStr) {
      try {
        await checkFileOptionsAuth.value(authStr)
      } catch (error) {
        return false
      }
    }
    router.push(v.path)
  }

  // 用户类型
  const userType = computed(() => {
    if (!userInfo.value) return ''
    // vip个人会员 qy_vip企业会员 pdf_pid   >0为子账号
    if (userInfo.value.is_child == 1) return 'sub_account'
    if (userInfo.value.qy_vip == 1) return 'qy_vip'
    if (userInfo.value.vip == 1) return 'ge_vip'
    return 'none'
  })

  // 企业会员点击续费
  const renewal = async () => {
    console.log('续费')
    const result = await getLastOrder()
    payRenewDialogRef.value.openDialog(result)
  }

  // 企业会员点击扩容
  const expand = async () => {
    console.log('扩容')
    const result = await getLastOrder()
    payDilatationDialogRef.value.openDialog(result)
  }

  // 获取上一次开通会员信息
  const getLastOrder = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await getLastOrderApi()
        resolve(result)
      } catch (error) {
        console.log(error)
      }
    })
  }

  const vipObj:any = {
    ge_vip: {
      text: '个人会员',
      timeKey: 'yxtime',  // 到期时间字段
      tips: '此功能为企业会员功能，需要开通企业会员才能使用。',
      iconM: require('@/assets/member/mobile/u001.png'), // 个人会员图标
      btn: [
        { text: '续费', to: '/member/join', type: 'page' },
        { text: '升级企业会员', to: '/member/join?type=2', type: 'page', class: 'expand' }
      ],
      isCrown: true,  // 是否显示皇冠
      path: '/member/join?type=2'
    },
    qy_vip: {
      text: '企业会员',
      timeKey: 'qy_yxtime',  // 到期时间字段
      tips: '抱歉目前管理员暂未开放此功能。',
      iconM: require('@/assets/member/mobile/u002.png'), // 企业会员图标
      btn: [
        { text: '续费', type: 'click', action: renewal },
        { text: '扩容', type: 'click', class: 'expand', action: expand },
      ],
      isCrown: true,  // 是否显示皇冠
    },
    sub_account: {
      text: '子账号',
      tips: '抱歉子账号暂未开放此功能。',
      iconM: '',
      isCrown: false,  // 是否显示皇冠
    },
    none: {
      text: '普通用户',
      tips: '此功能为会员功能，需要开通会员才能使用。',
      iconM: '',
      isCrown: false,  // 是否显示皇冠
      btn: [
        { text: '立即开通', to: '/member/join', type: 'page' }
      ],
      path: '/member/join'
    }
  }

  const userVip = computed(() => {
    if (!userInfo.value) return ''
    return vipObj[userType.value as keyof typeof vipObj]
  })

  // vip按钮点击
  const handleRenewal = (item: any) => {
    if (item.type == 'page') {
      router.push(item.to);
    } else if (item.type == 'click') {
      item.action()
    }
  }

  const checkRole = (role: string[]) => {
    console.log(role, 'role')
    console.log(userType.value, 'userType')
    let auth = false, vipInfo = vipObj[userType.value as keyof typeof vipObj]
    if (role && role.length) auth = role.includes(userType.value)
    else auth = true
    if (!auth) {
      ElMessageBox.confirm(`${vipInfo.tips}`, '提示', {
        confirmButtonText: '去开通',
        cancelButtonText: '取消'
      }).then(() => {
        router.push(vipInfo.path)
      }).catch(() => {
        
      })
    }
    return auth
  }

	return { 
    checkAuth,
    checkAuthBack,
    handleShowText,
    userInfo,
    handleVipTip,
    toPath,
    userType,
    vipObj,
    checkRole,
    userVip,
    handleRenewal,
    router,
    renewal,
    checkFileOptionsAuth,

    PayRenewDialog,
    payRenewDialogRef,
    PayDilatationDialog,
    payDilatationDialogRef,
  }
}

import { defineAsyncComponent } from 'vue'
import { createRouter, createWebHistory, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { ElMessage, ElMessageBox } from 'element-plus';
import { getToken } from "@/utils/auth";
import { useUser } from '@/composable/useUser'
import { getUrlParam } from '@/utils/common'
import { useCheckFileOptionsAuth } from "@/utils/checkFileOptionsAuth"

// const { checkAuth, checkRole, checkAuthBack, userType, checkFileOptionsAuth } = useUser()

import Layout from '@/layout/index.vue'

import Home from '@/views/home/index.vue'
import HomeAbout from '@/views/home/about/index.vue'
import HomeHelp from '@/views/home/help/index.vue'
import HomeContact from '@/views/home/contact/index.vue'

import Pdf from '@/views/pdf/index.vue'
import PdfResult from '@/views/pdf/result.vue'

import Login from '@/views/login/index.vue'
import Register from '@/views/login/register.vue'
import ResetPassword from '@/views/login/resetPassword.vue'

import Join from '@/views/join/index.vue'

import MemberLayout from '@/views/member/index.vue'
import MemberFile from '@/views/member/file/index.vue'
// const MemberFile = defineAsyncComponent(() => import('@/views/member/file/index.vue'))
import MemberUser from '@/views/member/user/index.vue'
import MemberMember from '@/views/member/member/index.vue'
import MemberRecycle from '@/views/member/recycle/index.vue'
import MemberNotice from '@/views/member/notice/index.vue'
import MemberTrajectory from '@/views/member/file/components/Track.vue'
import MemberCustomize from '@/views/member/customize/index.vue'
import MemberAccount from '@/views/member/account/index.vue'
import MemberOther from '@/views/member/other/index.vue'
import MemberBrand from '@/views/member/other/brand/index.vue'
import MemberQrCode from '@/views/member/other/qrCode/index.vue'
import MemberForm from '@/views/member/other/form/index.vue'
import MemberClue from '@/views/member/other/clue/index.vue'
import MemberWhiteList from '@/views/member/other/whiteList/index.vue'

import Privilege from '@/views/member/member/Privilege.vue'
import {useUserStoreHook} from "@/store/modules/user";

const PhoneNotice = defineAsyncComponent(() => import('@/views/member/file/components/phone/Notice.vue'))
const PhoneFolder = defineAsyncComponent(() => import('@/views/member/file/components/phone/Folder.vue'))
const PhoneSubLink = defineAsyncComponent(() => import('@/views/member/file/components/phone/SubLink.vue'))

const routes: Array<RouteRecordRaw> = [
  {
    path: '/home',
    name: 'home',
    redirect: '/home/index',
    component: Layout,
    children: [
      {
        path: '/home/index',
        name: 'home-index',
        component: Home,
        meta: {
          title: '首页',
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/home/about',
        name: 'home-about',
        component: HomeAbout,
        meta: {
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/home/help',
        name: 'home-help',
        component: HomeHelp,
        meta: {
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/home/contact',
        name: 'home-contact',
        component: HomeContact,
        meta: {
          showLogo: true,
          isAuth: false,
          showFooter: true
        }
      },
    ]
  },
  {
    path: '/pdf',
    name: 'pdf',
    redirect: '/pdf/index',
    component: Layout,
    children: [
      {
        path: '/pdf/index',
        name: 'pdf-index',
        component: Pdf,
        meta: {
          isAuth: false,
          showFooter: true
        }
      },
      {
        path: '/pdf/result/:ids',
        name: 'pdf-result',
        component: PdfResult,
        meta: {
          isAuth: true
        }
      }
    ]
  },
  {
    path: '/member/join',
    name: 'join',
    component: Join,
    meta: {
      title: '会员中心',
      isAuth: true,
      // 登录显示
      loginShow: true
    }
  },
  // 会员中心
  {
    path: '/member',
    name: 'member',
    redirect: '/member/index',
    component: Layout,
    children: [
      {
        path: '/member/index',
        name: 'member-index',
        redirect: '/member/file',
        component: MemberLayout,
        children: [
          {
            path: '/member/file',
            name: 'member-file',
            component: MemberFile,
            meta: {
              title: '文件管理',
              icon: 'menu01.png',
              iconA: 'menu01-.png',
              isAuth: true,
              isVip: false,
              showArrow: true
            }
          },
          {
            path: '/member/user',
            name: 'member-user',
            component: MemberUser,
            meta: {
              title: '账户资料',
              icon: 'menu02.png',
              iconA: 'menu02-.png',
              isAuth: true,
              isVip: false,
              showArrow: true,
              // hiddenArr: ['sub_account'] // 子账号隐藏
            }
          },
          {
            path: '/member/notice',
            name: 'member-notice',
            component: MemberNotice,
            meta: {
              title: '消息中心',
              icon: 'menu03.png',
              iconA: 'menu03-.png',
              isAuth: true,
              isVip: false,
              showArrow: true
            }
          },
          {
            path: '/member/member',
            name: 'member-member',
            component: MemberMember,
            meta: {
              title: '会员中心',
              icon: 'menu04.png',
              iconA: 'menu04-.png',
              isAuth: true,
              isVip: false,
              hiddenArr: ['sub_account'], // 子账号隐藏
              showArrow: true
            },
          },
          {
            path: '/member/recycle',
            name: 'member-recycle',
            component: MemberRecycle,
            meta: {
              title: '回收站',
              icon: 'menu05.png',
              iconA: 'menu05-.png',
              showTopNav: true,
              isAuth: true,
              authStr: 'recycle',
              showArrow: true
            }
          },
          {
            path: '/member/account',
            name: 'member-account',
            component: MemberAccount,
            meta: {
              title: '子账号管理',
              icon: 'menu06.png',
              iconA: 'menu06-.png',
              showTopNav: true,
              isAuth: true,
              authStr: 'child_user',
              role: ['qy_vip'],
              showArrow: true
            }
          },
          {
            path: '/member/other',
            name: 'member-other',
            component: MemberOther,
            redirect: '/member/other/qr-code',
            meta: {
              title: '其他',
              icon: 'menu07.png',
              iconA: 'menu07-.png',
              showTopNav: true,
              isAuth: true,
              authStr: '',
              showArrow: true
            },
            children: [
              {
                path: '/member/other/brand',
                name: 'member-brand',
                component: MemberBrand,
                meta: {
                  title: '品牌设置',
                  showTopNav: false,
                  isAuth: true,
                  authStr: 'pinpai',
                  role: ['qy_vip'],
                  showArrow: false,
                  hiddenArr: ['sub_account'] // 子账号隐藏
                }
              },
              {
                path: '/member/other/qr-code',
                name: 'member-qr-code',
                component: MemberQrCode,
                meta: {
                  title: '营销二维码',
                  showTopNav: false,
                  isAuth: true,
                  authStr: 'qrcode',
                  showArrow: false
                }
              },
              {
                path: '/member/other/form',
                name: 'member-form',
                component: MemberForm,
                meta: {
                  title: '营销表单',
                  showTopNav: false,
                  isAuth: true,
                  authStr: 'from',
                  showArrow: false
                }
              },
              {
                path: '/member/other/clue',
                name: 'member-clue',
                component: MemberClue,
                meta: {
                  title: '线索管理',
                  showTopNav: false,
                  isAuth: true,
                  authStr: 'from',
                  showArrow: false
                }
              },
              {
                path: '/member/other/white-list',
                name: 'member-white-list',
                component: MemberWhiteList,
                meta: {
                  title: '白名单',
                  showTopNav: false,
                  isAuth: true,
                  authStr: 'baimingdan',
                  showArrow: false
                }
              }
            ]
          },
          {
            path: '/member/trajectory',
            name: 'member-trajectory',
            component: MemberTrajectory,
            meta: {
              title: '浏览轨迹',
              icon: 'menu05.png',
              showTopNav: true,
              hidden: true,
              isAuth: true,
              authStr: 'visitor_trajectory'
            }
          },
          {
            path: '/member/phone/notice',
            name: 'phone-trajectory',
            component: PhoneNotice,
            meta: {
              title: '授权消息',
              icon: 'menu05.png',
              showTopNav: true,
              hidden: true,
              isAuth: true,
            }
          },
          {
            path: '/member/phone/folder',
            name: 'phone-folder',
            component: PhoneFolder,
            meta: {
              title: '文件夹',
              icon: 'menu05.png',
              showTopNav: true,
              hidden: true,
              isAuth: true,
            }
          },
          {
            path: '/member/phone/sub-link',
            name: 'phone-subLink',
            component: PhoneSubLink,
            meta: {
              title: '子链接',
              icon:'menu05.png',
              showTopNav: true,
              hidden: true,
              isAuth: true,
              authStr: 'add_fileurl'
            }
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    redirect: '/login/index',
    children: [
      {
        path: '/login/index',
        name: 'login-index',
        component: Login,
      },
      {
        path: '/login/register',
        name: 'login-register',
        component: Register,
      },
      {
        path: '/login/reset',
        name: 'login-reset',
        component: ResetPassword,
        meta: {
          title: '忘记密码'
        }
      }
    ]
  },
  // 会员权益
  {
    path: '/member/privilege',
    name: 'privilege',
    component: Privilege,
    meta: {
      title: '会员权益',
      icon: 'menu04.png',
      showTopNav: true
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(), // hash 模式
  // history: createWebHistory(process.env.BASE_URL),  // history 模式
  routes
})

declare global {
  interface Window { _hmt: any; }
}

router.beforeEach((to: any, from: any, next) => {
  let title = ''
  if (to.meta.title) {
    title = to.meta.title + '-';
  }
  title += '笔熊分享_为千万内容创作者提供内容保护，内容安全分享'
  document.title = title
  // console.log(to, 'to')
  if (to.matched.length === 0) {
    next('/pdf')
  } else {
    let authStr = to.meta?.authStr
    let isAuth = to.meta?.isAuth
    const { checkFileOptionsAuth } = useCheckFileOptionsAuth()
    
    if (isAuth) {
      if (getToken()) {
        useUserStoreHook().getUserInfo().then(() => {
          if (authStr) {
            checkFileOptionsAuth(authStr).then(() => {
              baidu(to)
              next()
            }).catch((error) => {
              console.log(error, '无权限')
              if (!from.path) next('/')
              else next(from.path)
            })
          } else {
            baidu(to)
            next()
          }
        }).catch((error) => {
          if (!from.path) next('/')
          else next(from.path)
        })        
      } else {
        next(`/login?redirect=${to.path}&params=${JSON.stringify(to.query? to.query : to.params)}`)
      }
    } else {
      baidu(to)
      next()
    }
  }
})

router.afterEach((to: any, from: any) => {
  // console.log(to, 'to')
  if (replaceWechatRedirectUri()) return false;
  if (to.path.includes('home') && document.getElementsByClassName('main-container')[0]) {
    document.getElementsByClassName('main-container')[0].scrollTop = 0
  }
})
/**
 * 处理百度统计代码
 */
function baidu(to: any) {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
}

/**
 * 处理微信授权回调redirect_uri
 */
function replaceWechatRedirectUri() {
  const w = location.href.indexOf('?');
  const j = location.href.indexOf('#');
  let state:any = getUrlParam('state')
  if (w !== -1 && j > w && state) {
    let code = getUrlParam('code')
    state = filterState(state)
    let url = `${location.origin}${location.pathname}#/login/index?code=${code}`;
    if (state.redirect) url += `&redirect=${state.redirect}`;
    if (state.params) url += `&params=${state.params}`;
    console.log(url, 'url')
    if (code && state) { location.replace(url)}
    return true;
  }
}

/**
 * state 处理
*/
function filterState (state:any) {
  if (state) {
    // 替换特定字符串
    state = state.replace('{', '');
    state = state.replace('}', '');
    // 逗号拆分字符串
    state = state.split(',')
    let obj = <any>{}
    state.forEach((item:any) => {
      let arr = item.split(':')
      obj[arr[0]] = arr[1]
    });
    return obj
  } else {
    return {}
  }
}

export default router

<template>
  <LoginContainer :formState="formState" />
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, watch } from 'vue'
import checkPc from '@/utils/checkPc'
import { isWxBrowser } from '@/utils/common'

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const LoginContainer = defineAsyncComponent(() => import('./components/PageContainer.vue'));

const formState = ref({
  tabList: [
    { name: '扫码登录', key: 'login-ewm' },
    { name: '密码登录', key: 'login-ps' }
  ],
  activeKey: 'login-ewm'
})


watch(
  () => isPC.value,
  () => {
    if (isPC.value) {
      formState.value.activeKey = 'login-ewm'
    } else {
      if (isWxBrowser()) {
        formState.value.tabList = [{ name: '微信登录', key: 'login-wx' }]
        formState.value.activeKey = 'login-wx'
      } else {
        formState.value.activeKey = 'login-ps'
      }
    }
  }
)

</script>

<style lang="scss" scoped>

</style>
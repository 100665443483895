import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { setupStore } from "@/store";

import useElementPlus from "element-plus"
import zhCn from "element-plus/es/locale/lang/zh-cn"

import '@/utils/w_rem.js'

import jssdk from 'weixin-js-sdk'

import '@/theme/index.scss'

const app = createApp(App);

import MyButton from '@/components/button.vue'
import CopyView from '@/components/CopyView.vue'
app.component('my-button', MyButton)
app.component('copy-view', CopyView)

import PButton from '@/components/Form/PButton.vue';
app.component('p-button', PButton)

import PTabs from '@/components/Form/PTabs.vue';
app.component('p-tabs', PTabs)

import VueLazyloadNext from 'vue-lazyload-next';
app.use(VueLazyloadNext, {
  // 添加一些配置参数 可不填
  // 懒加载默认加载图片
  loading: require('@/assets/load-data.gif'),
  // 加载失败后加载的图片
  // error: 'xxx.png',
  preLoad: 1.3, // 预加载高度的比例
  attempt: 3 // 尝试加载次数
});


setupStore(app);

app.use(useElementPlus, {
  locale: zhCn,
});

app.config.errorHandler = (err, vm, info) => {
  // 在此处处理错误
  console.log(err, vm, info)
}

app.config.globalProperties.$wx = jssdk

app.use(router).mount('#app')

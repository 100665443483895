<template>
  <div class="content-wrap h100 flex-y">
    <div class="flex-c-between mb14">
      <div class="flex-a-center">
        <p-tabs :tabs="tabList" v-model:current="activeIndex" :loadingData="loadingData" />
        <div class="read-all ml26 cursor-pointer" @click="readAll">全部已读</div>
      </div>
      <div class="flex-a-center">
        <el-button link :icon="Setting" class="setting-btn" @click="showSettings">消息设置</el-button>
      </div>
    </div>
    <!-- 内容列表 -->
    <notice-list :tabId="getActiveTabId" v-model:loading="loadingData" />
    <el-dialog v-model="settingsVisible" title="消息设置" width="920px">
      <settings v-if="settingsVisible" :tab-list="tabList" @close="handleSettingsClose" />
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { ElMessage } from "element-plus";
import { Setting } from "@element-plus/icons-vue";
import NoticeList from "@/views/member/notice/components/NoticeList.vue";
import Settings from "@/views/member/notice/components/Settings.vue";
import { readAllMsgApi, queryUnreadRemindApi } from "@/api/message";

/**
 * 当前选中选项卡
 */
const activeIndex = ref(0);

/**
 * 选项卡列表
 */
const tabList = ref([
  {tab: 'auth', name: '授权申请', tips: '授权申请', id: '4', read: 0, tabId: 'auth'},
  {tab: 'file', name: '文件提醒', tips: '文件提醒（包含操作记录，到期前提醒，到期提醒，访客提醒等提醒）', id: '3', read: 0, tabId: 'file'},
  {tab: 'account', name: '账号提醒', tips: '账户提醒（包含开通、续费、即将到期、已到期等提醒）', id: '2', read: 0, tabId: 'zhanghu'},
  {tab: 'system', name: '系统公告', tips: '系统公告（网站管理员后台发布）', id: '1', read: 0, tabId: 'xitong'},
]);

/**
 * 设置弹窗展示状态
 */
const settingsVisible = ref(false);

/**
 * 显示设置弹窗
 */
const showSettings = () => {
  settingsVisible.value = true;
};

/**
 * 设置弹窗关闭
 */
const handleSettingsClose = () => {
  settingsVisible.value = false;
}

/**
 * 判断是否有未读消息
 */
const hasUnreadMessage = async () => {
  let data:any = await queryUnreadRemindApi()
  tabList.value.forEach(item => {
    const key = item.tabId, value = data[key] || 0
    item.read = value
    item.name = item.name.replace(/\(\d+\)/, '')
    item.name = item.name + `(${value})`
  })
}
hasUnreadMessage();

/**
 * 获取选中选项卡的id
 */
const getActiveTabId = computed(() => {
  return tabList.value[activeIndex.value].id;
})

// 全部已读
const readLoading = ref(false)
const readAll = async () => {
  // 判断是否有未读消息
  const hasUnread = tabList.value.some(item => item.read > 0)
  if (!hasUnread) return ElMessage.warning('没有未读消息')
  if (readLoading.value) return
  try {
    readLoading.value = true
    await readAllMsgApi()
    ElMessage.success('已全部标记为已读')
    hasUnreadMessage()
  } catch (error) {
    console.log(error)
  } finally {
    readLoading.value = false
  }
}

// j数据加载处理
const loadingData = ref(false)

</script>

<style scoped lang="scss">
.content-wrap {
  padding: 0.32rem 0.32rem 0.82rem 0.32rem;
  .read-all {
    font-size: 0.15rem;
    color: #FF7B6D;
  }
  .setting-btn {
    font-size: 0.15rem;
    color: #795754;
    :deep(.el-icon){
      color: #FF7B6D;
      font-size: 0.17rem;
    }
  }
}

:deep(.el-badge__content.is-fixed.is-dot){
  right: 0;
}
:deep(.el-tabs){
  margin-top: 0.34rem;
  height: calc(100% - 0.5rem - 0.34rem);
}
:deep(.el-tabs__content){
  height: 100%;
  overflow: auto;
}
:deep(.el-tab-pane){
  height: 100%;
}
#notice{
  height: 100%;
  :deep(.main-title){
    border-bottom: none;
  }
  .tabs-wrap{
    position: relative;
    height: calc(100% - 0.55rem);
    
  }
}
</style>

import { defineStore } from "pinia";
import { store } from "@/store";
import { ElMessage } from "element-plus";
import { logoutApi, getUserInfoApi } from "@/api/login";
import { getWebInfoApi, getInviteInfoApi, getWechatShareLinkApi } from '@/api/common'
import { storageLocal } from "@/utils/storage";
import { getToken, setToken, removeToken, getUserId, setUserId, removeUserId } from "@/utils/auth";
import { queryUnreadMsgNumApi } from "@/api/message";
import router from "@/router";

const data = getToken();
let token = "";
if (data) {
  token = data
}

export const useUserStore = defineStore({
  id: "pure-user",
  state: () => ({
    token,
    userInfo: <any>{},
    siteInfo: <any>null,
    inviteInfo: <any>null,
    wechatShareLink: <any>null,
    qrCodeSize: 200,
    unreadMsgCount: 0, // 未读消息数
  }),
  actions: {
    SET_TOKEN(token: any) {
      this.token = token;
    },
    SET_USER_INFO(userInfo: any) {
      if (!userInfo) return
      this.userInfo = userInfo;
      setToken(userInfo.token);
    },
    SET_SITE_INFO(siteInfo: any) {
      this.siteInfo = siteInfo;
    },
    SET_INVITE_INFO(inviteInfo: any) {
      this.inviteInfo = inviteInfo;
    },
    SET_WECHAT_SHARE_LINK(wechatShareLink: any) {
      this.wechatShareLink = wechatShareLink;
    },
    SET_QRCODE_SIZE(qrCodeSize: any) {
      this.qrCodeSize = qrCodeSize;
    },
    SET_UNREAD_MSG_COUNT(unreadMsgCount: any) {
      this.unreadMsgCount = unreadMsgCount;
    },
    // 获取用户信息
    async getUserInfo() {
      const res = await getUserInfoApi();
      this.SET_USER_INFO(res);
      return Promise.resolve(res);
    },
    // 获取网站信息
    async getWebInfo() {
      const res = await getWebInfoApi();
      this.SET_SITE_INFO(res);
      return Promise.resolve(res);
    },
    // 获取邀请信息
    async getInviteInfo() {
      const res = await getInviteInfoApi();
      this.SET_INVITE_INFO(res);
      return Promise.resolve(res);
    },
    // 获取微信分享链接
    async getWechatShareLink() {
      try {
        const res: any = await getWechatShareLinkApi();
        this.SET_WECHAT_SHARE_LINK(res.data);
        this.SET_QRCODE_SIZE(res.qrcode);
        return Promise.resolve(res);
      } catch (error) {
        console.log(error, 'getWechatShareLink');
      }
    },
    // 去登录
    toLogin(route: any, router: any) {
      route = router?.currentRoute.value;
      router.push(`/login?redirect=${route.path}&params=${JSON.stringify(route.query ? route.query : route.params)}`)
    },
    // 登录成功提示
    loginSuccess(info: any) {
      ElMessage.success('登录成功')
      this.SET_USER_INFO(info)
      setUserId(info.id)
      let route = router?.currentRoute.value;
      // 谷歌统计代码
      (window as any).gtag('event', 'conversion', { 'send_to': 'AW-16484944236/Lb7TCMiU0ZkZEOyS0bQ9' });
      setTimeout(() => {
        // 跳转登录
        if (route.query?.redirect) {
          router.push({
            path: <string>route.query?.redirect,
            query: Object.keys(<string>route.query?.params).length > 0 ? JSON.parse(<string>route.query?.params) : '',
          });
        } else {
          router.push('/');
        }
      }, 1500)

    },
    // 退出登录 清空缓存
    async logOut() {
      await logoutApi();
      this.token = "";
      this.userInfo = {};
      removeToken();
      removeUserId()
      storageLocal.clear();
      ElMessage.success("退出成功");
      window.location.href = window.location.origin + '/#/'; // 去登录页
      // ElMessage({ 
      //   message: "退出成功",
      //   type: "success",
      //   onClose: () => {
      //     window.location.href = window.location.origin + '/#/'; // 去登录页
      //   }
      // })
    },
    // 获取未读消息数
    async getUnreadMessage() {
      const res:any = await queryUnreadMsgNumApi();
      this.SET_UNREAD_MSG_COUNT(res);
    }
  }
});

export function useUserStoreHook() {
  return useUserStore(store);
}

if (data && data != undefined && data != null && !useUserStoreHook().userInfo.token) {
  useUserStoreHook().getUserInfo();
}

if (!useUserStoreHook().siteInfo) {
  useUserStoreHook().getWebInfo()
}

if (!useUserStoreHook().inviteInfo) {
  useUserStoreHook().getInviteInfo();
}

if (!useUserStoreHook().wechatShareLink) {
  useUserStoreHook().getWechatShareLink();
}
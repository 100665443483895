<template>
  <div class="account-container flex p32">
    <div class="account-left mr10">
      <DepartmentView ref="departmentViewRef" @change="changeDepartment" />
    </div>
    <div class="account-right flex-auto flex-y">
      <div class="flex-a-center pl10 mb10">
        <p-button text="添加账号" icon="member/u007.png" @click="openCreateEmployee" />
      </div>
      <p-table ref="tableRef" v-bind="tableConfig" @edit="editEmployee">
        <template #bumen="{ row }">
          <span>{{ row.bumen && row.bumen.title }}</span>
        </template>
        <template #quanxian="{ row }">
          <span>{{ row.quanxian && row.quanxian.title }}</span>
        </template>
        <template #status="{ row }">
          <span v-if="row.status == 'normal'">启用</span>
          <span v-else>暂停</span>
        </template>
      </p-table>
    </div>
    <CreateEmployee ref="createEmployeeRef" @success="refresh" />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, defineAsyncComponent } from 'vue'
import { getEmployeeListApi, deleteEmployeeApi } from '@/api/other'

// 引入组件
const DepartmentView = defineAsyncComponent(() => import('./components/DepartmentView.vue'))
const PTable = defineAsyncComponent(() => import('@/components/PTable/index.vue'))
const CreateEmployee = defineAsyncComponent(() => import('./components/CreateEmployee.vue'))

const tableConfig:any = reactive({
  api: getEmployeeListApi, // 接口地址
  delApi: deleteEmployeeApi,
  limit: 10, // 每页显示条数
  columns: [
    { prop: 'username', label: '用户名' },
    { prop: 'nickname', label: '昵称' },
    { prop: 'bumen', label: '部门', type: 'slot' },
    // { prop: 'mobile', label: '手机号' },
    { prop: 'quanxian', label: '角色', type: 'slot' },
    { prop: 'status', label: '状态', type: 'slot' },
  ],
  data: [],
  params: {}
})

const changeDepartment = (data: any) => {
  console.log(data, 'changeDepartment')
  tableConfig.params.bumen_id = data.id
}

const tableRef = ref()
const refresh = () => {
  tableConfig.params.bumen_id = ''
  tableRef.value.refresh()
}

const createEmployeeRef = ref()
const openCreateEmployee = () => {
  createEmployeeRef.value.openDialog({ type: 'add', data: null })
}

const editEmployee = (data: any) => {
  console.log(data, 'editEmployee')
  createEmployeeRef.value.openDialog({ type: 'edit', data })
}

</script>

<style scoped lang="scss">
.account-container {
  height: 100%;
  .account-left {
    width: 3rem;
  }
  // .account-right {
    
  // }
}
</style>
<script setup lang="ts">
import { onMounted, defineAsyncComponent } from 'vue';
import { storeToRefs } from 'pinia';
import { useUserStoreHook } from "@/store/modules/user";
import checkPc from "@/utils/checkPc";
import { useUser } from '@/composable/useUser';

const userStore = useUserStoreHook()
const { userInfo } = storeToRefs(userStore)
const { userType, router } = useUser()

// 定义组件
const Layout = defineAsyncComponent(() => import('@/layout/index.vue'));
const JoinPc = defineAsyncComponent(() => import('./components/JoinPc.vue'));
const JoinPhone = defineAsyncComponent(() => import('./components/JoinPhone.vue'));

const isPC = checkPc()

onMounted(() => {
  if (userType.value == 'qy_vip' || userType.value == 'sub_account') router.go(-1)
})
</script>

<template>
  <layout :footer="false" :parent="false">
    <join-pc v-if="isPC" :userInfo="userInfo" />
    <div class="phone-wrap" v-else>
      <join-phone :userInfo="userInfo" />
    </div>
  </layout>
</template>

<style scoped lang="scss">
.phone-wrap {
  padding-bottom: 0.6rem;
}
</style>

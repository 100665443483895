<script setup lang="ts">
import { computed, defineAsyncComponent, ref } from "vue";
import { useUserStoreHook } from "@/store/modules/user";
import { storeToRefs } from "pinia";
import { editUserInfoApi } from "@/api/login";
import { ElMessage } from "element-plus";

const userStore = useUserStoreHook();
const storeData = storeToRefs(userStore);
const formData = storeData.userInfo;

// 定义组件
const UserInfo = defineAsyncComponent(() => import('./components/UserInfo.vue'));
const SafeInfo = defineAsyncComponent(() => import('./components/SafeInfo.vue'));
const AuthInfo = defineAsyncComponent(() => import('./components/AuthInfo.vue'));

/**
 * 加载动画显示状态
 */
const loading = ref(false);

/**
 * 保存信息
 */
const saveData = (field: string, value: string, data: any = null, showMsg: boolean = true) => {
  if (!data) {
    if (formData.value[field] === value) return ElMessage.info('请修改后再保存');
    formData.value[field] = value;
  } else {
    formData.value = Object.assign({}, formData.value, data);
  }
  editUserInfoApi(formData.value).then(() => {
    if (showMsg) ElMessage.success('保存成功');
    refresh();
  }).finally(() => {
    loading.value = false;
  })
};

/**
 * 刷新页面
 */
const refresh = async () => {
  await userStore.getUserInfo();
}
</script>

<template>
  <el-scrollbar id="user" class="form-view" v-loading="loading">
    <user-info :form-data="formData" @save-data="saveData" />

    <safe-info :form-data="formData" @save-data="saveData" @refresh-data="refresh" />

    <auth-info :form-data="formData" @save-data="saveData" />
  </el-scrollbar>
</template>

<style scoped lang="scss">
@use "@/theme/index.scss";

#user {
  max-height: 100%;
  overflow: auto;

  .auth-info-form {
    margin-top: 0.48rem;

    &.user-info-form{
      :deep(.el-input) {
        width: 4.6rem;
      }
    }

    &.edit {
      :deep(.el-input__wrapper) {
        height: 0.41rem;
        background: rgba(24, 144, 255, 0);
        border: 0.01rem solid #C0BFBF;
        border-radius: 0.05rem;
      }
    }
  }
}
</style>

import { ElMessage } from 'element-plus';

/**
 * 自定义手机号验证规则 el表单使用
 */
 export function validatePhone (rule: any, value: string, callback: any) {
  if (!value) {
    callback(new Error('请输入手机号'))
  } else if (!/^1[3456789]\d{9}$/.test(value)) {
    callback(new Error('请输入正确的手机号'))
  } else {
    callback()
  }
};

export function validatePhoneValue (value: any, showError = 1) {
  if (!value) {
    if (showError == 1) ElMessage.error('请输入手机号')
    return false
  }
  else if (!/^1[3456789]\d{9}$/.test(value)) {
    if (showError == 1) ElMessage.error('请输入正确的手机号')
    return false
  }
  else return true
};

/**
 * 自定义验证码验证规则
 */
export function validateCode (rule: any, value: string, callback: any) {
  if (!value) {
    callback(new Error('请输入验证码'))
  } else if (!/^\d{4}$/.test(value)) {
    callback(new Error('请输入4位验证码'))
  } else {
    callback()
  }
};

/**
 * 验证身份证是否合法
 * @param id 身份证号
 * @returns {boolean} 是否合法
 */
export function isValidChineseID (id: string): boolean {
  const regex = /^[1-9]\d{16}[Xx\d]$/;
  return regex.test(id);
}

/**
 * 验证密码长度 6-20位
 */
export function validatePassword (value: string) {
  if (!value) {
    ElMessage.error('请输入密码')
    return false
  } else if (value.length < 6 || value.length > 20) {
    ElMessage.error('密码长度为6-20位')
    return false
  } else {
    return true
  }
};

<template>
  <div>
    <span v-if="type == 'normal'" class="my-btn btn cursor-pointer" @click="handleClick">{{ text }}</span>
    <span v-if="type == 'form'" class="my-btn-form btn cursor-pointer" @click="handleClick">{{ text }}</span>
    <span v-if="type == 'step-form'" class="my-btn-step btn cursor-pointer" @click="handleClick">{{ text }}</span>
  </div>
</template>

<script setup lang="ts">

const props = defineProps({
  text: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'normal'
  }
})

const emit = defineEmits(['click'])
const handleClick = () => {
  emit('click')
}

</script>

<style lang="scss" scoped>
.btn {
  font-size: 0.24rem;
  color: #FFFFFF;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.my-btn {
  width: 2.5rem;
  height: 0.6rem;
  line-height: 0.6rem;
  background: #FE0000;
  border-radius: 0.3rem;
}
.my-btn-form {
  width: 100%;
  height: 0.64rem;
  line-height: 0.64rem;
  background: #EDAAA8;
  border-radius: 0.1rem;
  font-size: 0.23rem;
  font-weight: bold;
}
.my-btn-step {
  width: 2.99rem;
  height: 0.42rem;
  background: linear-gradient(260deg, #FE0000, #F85353);
  font-size: 0.18rem;
}
@media screen and (max-width: 768px) {
  .my-btn-form {
    height: 0.81rem;
    background: #F87985;
    border-radius: 0.41rem;
    font-size: 0.3rem;
  }
}
</style>

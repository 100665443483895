import request from '@/utils/request';

const base = '/Filemanage/'

// 文件加密
/* id（文件id）
	looknum（打开次数(次)  默认未设置 0）
	password（访问密码  默认为设置 空）
	one_look_time（单词访问时长(秒)  默认未设置  0）
	rennum（访问人数限制(人)  默认未设置  0）
	sctime（有效期  默认永久:0、1天:1、7天:2、1个月:3 、指定日期：2023-12-18）
*/
export const fileEncryptApi = (data: object) => {
	return request({
		url: `${base}encrypt`,
		method: 'post',
		data,
	});
}

// 分享列表
export const fileShareApi = (data: object) => {
	return request({
		url: `${base}share`,
		method: 'get',
		params: data,
	});
}

// 文件列表
export const fileListApi = (data: object) => {
	return request({
		url: `${base}lst`,
		method: 'get',
		params: data,
	});
}

// 文件删除
export const fileDelApi = (data: object) => {
	return request({
		url: `${base}del`,
		method: 'post',
		data,
	});
}

// 文件上下架
export const fileStatusApi = (data: object) => {
	return request({
		url: `${base}status`,
		method: 'post',
		data,
	});
}

// 文件重命名
export const fileRenameApi = (data: object) => {
	return request({
		url: `${base}edittitle`,
		method: 'post',
		data,
	});
}

// 文件替换
export const fileReplaceApi = (data: object) => {
	return request({
		url: `${base}replace`,
		method: 'post',
		data,
	});
}

// 回收站列表
export const fileRecycleApi = (data: object) => {
	return request({
		url: `/Recycle/lst`,
		method: 'get',
		params: data,
	});
}

// 文件还原
export const fileRecycleRestoreApi = (data: object) => {
	return request({
		url: `/Recycle/restore`,
		method: 'post',
		data,
	});
}

// 文件彻底删除
export const fileRecycleDelApi = (data: object) => {
	return request({
		url: `/Recycle/del`,
		method: 'post',
		data,
	});
}

// 文件访客列表
export const fileVisitorApi = (data: object) => {
	return request({
		url: `/ip/ipfangke`,
		method: 'get',
		params: data,
	});
}

// 文件轨迹列表
export const fileTrackApi = (data: object) => {
	return request({
		url: `/ip/ipinfo`,
		method: 'get',
		params: data,
	});
}

/**
 * 文件预览提示语
 * @param {object} data
 * @param {string} data.id(文件id)
 * @param {string} data.bcz_remind(文档不存在提醒)
 * @param {string} data.xj_remind（文档下架提醒）
 * @param {string} data.dq_remind（文档到期提醒）
 * @param {string} data.cs_remind（访问次数上限提醒）
 * @param {string} data.rs_remind（访问人数上限提醒）
*/
export const filePreviewTipApi = (data: object) => {
	return request({
		url: `${base}remind`,
		method: 'post',
		data,
	});
}

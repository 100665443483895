import { getAddressApi } from "@/api/member";
import { ElMessage } from "element-plus";
import { useCheckFileOptionsAuth } from '@/utils/checkFileOptionsAuth'

export const useAddress = () => {

const { checkFileOptionsAuth } = useCheckFileOptionsAuth();
  /**
 * 点击查看位置
 */
  const showAddress = async (row: any,  type: number, success?: (() => void)) => {
    await checkFileOptionsAuth('address');

    if(!row.id || !type) return ElMessage.error('缺少必要参数。');
    const res: any = await getAddressApi({
      id: row.id,
      type
    });
    if (res.code) {
      ElMessage.error(res.msg || res.message);
      return;
    } else {
      row.addressStr = res.address;
      success && success();
    }
  }

  return { showAddress }
}
<template>
  <template v-if="isPC">
    <Layout :parent="false" :footer="false">
      <div class="main-content reset-main mt87">
        <div class="title">忘记密码</div>
        <div class="step-box flex mt90">
          <div
            v-for="(v, i) in stepList"
            :key="i"
            :class="['step-item', 'flex-column-center', {'active': i <= hasStep}]"
          >
            <div class="num-box flex">
              <div class="line line-l"></div>
              <p class="num mb15">{{ i + 1 }}</p>
              <div class="line line-r"></div>
            </div>
            <p class="text">{{ v.name }}</p>
          </div>
        </div>
        <div class="step-container flex-column-center mt100">
          <component :is="stepList[hasStep].component" @changeStep="changeStep" />
        </div>
      </div>
    </Layout>
  </template>
  <template v-else>
    <LayoutM :parent="false" :showTab="false">
      <component :is="stepListM[hasStepM].component" @changeStep="changeStep" />
    </LayoutM>
  </template>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent } from 'vue'
import { ElMessage } from 'element-plus';
import checkPc from '@/utils/checkPc'
import { editPasswordApi } from '@/api/login'
import { useRoute, useRouter } from "vue-router"

const router = useRouter();
const route = useRoute();

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const Layout = defineAsyncComponent(() => import('@/layout/index.vue'));
const LayoutM = defineAsyncComponent(() => import('@/layout/phone/index.vue'));
const TestPhone = defineAsyncComponent(() => import('./components/reset/TestPhone.vue'));
const ResetPass = defineAsyncComponent(() => import('./components/reset/ResetPass.vue'));
const ResetSuccess = defineAsyncComponent(() => import('./components/reset/ResetSuccess.vue'));
const MobileReset = defineAsyncComponent(() => import('./components/reset/MobileReset.vue'));

// 定义变量
const stepList = [
  {name: '验证身份', component: TestPhone},
  {name: '重置登录密码', component: ResetPass},
  {name: '重置成功', component: ResetSuccess}
]
const hasStep = ref(0)

const stepListM = [
  {name: '验证身份', component: MobileReset},
  {name: '重置成功', component: ResetSuccess}
]
const hasStepM = ref(0)

const formData = ref({})

const changeStep = async (e:any) => {
  let { step, data } = e
  if (isPC.value) {
    if (step < 2) hasStep.value = step
    formData.value = { ...formData.value, ...data }
    if (step == 2) {
      // console.log(formData.value, 'formData')
      // 密码修改
      const res:any = await editPasswordApi(formData.value)
      hasStep.value = 2
    }
  } else {
    hasStepM.value = step
  }
}

</script>

<style lang="scss" scoped>
.reset-main {
  background: var(--next-color-white);
  padding: 0.9rem;
  .title {
    font-size: 0.27rem;
    color: #444141;
  }
  .step-box {
    justify-content: space-around;
    // position: relative;
    .step-item {
      flex-grow: 1;
      .num-box {
        position: relative;
        width: 100%;
        justify-content: center;
        .num {
          width: 0.42rem;
          height: 0.42rem;
          text-align: center;
          line-height: 0.42rem;
          background: #787878;
          border-radius: 50%;
          font-size: 0.3rem;
          font-family: Arial;
          color: #FFFFFF;
          border: #FFFFFF solid 0.1rem;
          box-sizing: content-box;
          position: relative;
          z-index: 10;
        }
        .line {
          width: 50%;
          height: 0.01rem;
          background-color: #828282;
          position: absolute;
          top: calc((0.42rem) / 2 + 0.1rem);
          z-index: 1;
          &.line-l {
            left: 0;
          }
          &.line-r {
            right: 0;
          }
        }
      }
      .text {
        font-size: 0.23rem;
        color: #262626;
      }
      &.active {
        .num {
          background: #FE0000;
        }
        .text {
          color: #FE0000;
        }
      }
      &:first-child {
        .num-box {
          .line {
            &.line-l {
              height: 0;
            }
          }
        }
      }
      &:last-child {
        .num-box {
          .line {
            &.line-r {
              height: 0;
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width: 768px) {
  :deep(.top-navigation) {
    background: #FF9595 !important;
    color: #FFFFFF !important;
    .go-back {
      color: #FFFFFF !important;
    }
  }
}
</style>
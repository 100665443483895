<template>
  <router-view/>
</template>

<style scoped lang="scss">
@media screen and (min-width: 769px) {
  :deep(.pc-wrap){
    display: block;
  }
  :deep(.phone-wrap){
    display: none;
  }
}
@media screen and (max-width: 768px) {
  :deep(#app){
    min-width: 0;
  }
  :deep(.pc-wrap){
    display: none;
  }
  :deep(.phone-wrap){
    display: block;
  }
}
</style>

<script setup lang="ts">
import {computed, defineAsyncComponent, ref} from "vue";
import {singlePageApi, submitMessageApi} from '@/api/common'
import {ElMessage} from "element-plus";
import {validatePhone} from "@/utils/validate";

// 引入组件
const GadeMap = defineAsyncComponent(() => import('./components/GadeMap.vue'));

// 表单信息
const contact = ref(null);
const contactPhone = ref(null);

interface ruleForm {
  name: string,
  content: string,
  tel: string | number
}
const form = ref<ruleForm>({
  name: '',
  content: '',
  tel: ''
});

const rules = {
  name: [{ required: true, message: '请输入姓名', trigger: 'blur' }],
  content: [{ required: true, message: '请输入留言内容', trigger: 'blur' }],
  tel: [
    { required: true, message: '请输入联系电话', trigger: 'blur' },
    { validator: validatePhone, trigger: 'blur' },
  ]
};

const submitForm = async (formEl: any) => {
  if (!formEl) return
  await formEl.validate(async (valid: any, fields: any) => {
    if (valid) {
      await submitMessageApi(form.value);
      ElMessage.success('留言成功');
      formEl.resetFields();
    } else {
      console.log('error submit!', fields)
    }
  })
};

/**
 * 联系我们内容
 */
const mainContent = ref<any>({
  address: '',
  pic: '',
  company_name: ''
});

// 获取单页内容
const getSinglePage = async () => {
  mainContent.value = await singlePageApi({id: 2});
}

/**
 * 获取公司地址
 */
const companyAddress = computed(() => {
  if (mainContent.value.address) {
    return mainContent.value.address.split('：')[1] || mainContent.value.address.split(':')[1];
  } else {
    return ''
  }
});

/**
 * 获取公司名称
 */
const companyName = computed(()=> {
  if (mainContent.value.company_name) {
    return mainContent.value.company_name.split('：')[1] || mainContent.value.company_name.split(':')[1];
  } else {
    return ''
  }
})

getSinglePage()

const companyInfoBg1 = `url(${require('@/assets/contact/company-info.png')})`;
const companyInfoBg2 = `url(${require('@/assets/contact/company-info2.png')})`;

</script>

<template>
  <div id="contact" style="background-color: #fff;">
    <div class="banner pc-wrap"
      :style="`background: url(${require('@/assets/contact/banner.png')}) center;background-size: cover;`"></div>
    <div class="banner phone-wrap"
      :style="`background: url(${require('@/assets/contact/banner2.png')}) center;background-size: cover;`"></div>

    <div class="content-wrap">
      <div class="contact-us">
        <h1>联系我们</h1>

        <div class="card contact-information">
          <div class="left">
            <h2>联系方式</h2>

            <table>
              <tbody>
                <tr>
                  <td>联系人</td>
                  <td>{{ mainContent?.keywords || '无' }}</td>
                </tr>
                <tr>
                  <td>电子邮件</td>
                  <td>{{ mainContent.description || '无' }}</td>
                </tr>
                <tr>
                  <td>微信号</td>
                  <td>
                    <img v-if="mainContent.pic" :src="mainContent.pic" alt="">
                  </td>
                </tr>
              </tbody>
            </table>

            <h2>联系地址</h2>

            <p>{{ mainContent?.address }}</p>
          </div>

          <div class="right">
            <gade-map :address="companyAddress" v-if="companyAddress" />
          </div>
        </div>
      </div>

      <div class="consult">
        <h2 class="pc-wrap">留言咨询</h2>
        <h1 class="phone-wrap">留言咨询</h1>

        <div class="consult-form">
          <div class="form-wrap card pc-wrap">
            <el-form :model="form" class="form" :rules="rules" ref="contact" label-position="left">
              <el-form-item class="input-wrap" prop="name" label="姓名">
                <el-input link placeholder="请输入您的姓名" v-model="form.name" />
              </el-form-item>

              <el-form-item class="input-wrap" prop="content" label="留言">
                <el-input link placeholder="请输入留言内容" v-model="form.content" />
              </el-form-item>

              <el-form-item class="input-wrap" prop="tel" label="联系方式">
                <el-input link placeholder="请输入您的电话" v-model="form.tel" />
              </el-form-item>

              <el-form-item class="input-wrap btn-wrap">
                <el-button @click="submitForm(contact)">马上提交</el-button>
              </el-form-item>
            </el-form>
          </div>

          <div class="phone-wrap">
            <el-form :model="form" class="form" :rules="rules" ref="contactPhone" label-position="left">
              <div class="top-wrap">
                <div>
                  <el-form-item class="input-wrap" prop="name" label="姓名">
                    <el-input link placeholder="请输入您的姓名" v-model="form.name" />
                  </el-form-item>

                  <el-form-item class="input-wrap" prop="tel" label="联系方式">
                    <el-input link placeholder="请输入您的电话" v-model="form.tel" />
                  </el-form-item>
                </div>

                <div>
                  <el-form-item class="input-wrap leave-message" prop="content" label="留言">
                    <el-input type="textarea" link placeholder="请输入留言内容" v-model="form.content" />
                  </el-form-item>
                </div>
              </div>

              <div class="btn-wrap">
                <el-button type="primary" color="#BC0507" @click="submitForm(contactPhone)">马上提交</el-button>
              </div>
            </el-form>
          </div>

          <div class="company-info card">
            <h3 class="pc-wrap">公司信息</h3>
            <h2 class="phone-wrap">公司信息</h2>

            <table>
              <tbody>
                <tr>
                  <td>公司名称</td>
                  <td>{{ companyName }}</td>
                </tr>
                <tr>
                  <td>公司地址</td>
                  <td>{{companyAddress}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
#contact {
  * {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    box-sizing: border-box;

    &::before,
    &::after {
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }
  }

  li {
    list-style: none;
  }

  .banner {
    width: 100%;
    height: 5.95rem;
  }

  .content-wrap {
    padding-top: 0.8rem;
    padding-bottom: 1.02rem;
    width: 13.13rem;
    margin: auto;

    .card {
      background: #FFFFFF;
      border: 0.01rem solid rgba(209, 209, 209, 0.23);
      box-shadow: 0 0.07rem 0.29rem 0 rgba(194, 194, 194, 0.35);
    }

    h1 {
      font-size: 0.41rem;
      font-family: SourceHanSansSC;
      font-weight: bold;
      color: #313131;
      line-height: 0.69rem;
      text-align: center;
      position: relative;

      &::after {
        content: '';
        position: absolute;
        bottom: -0.19rem;
        left: 0;
        right: 0;
        margin: auto;
        width: 0.33rem;
        height: 0.03rem;
        background: #FE0000;
      }
    }

    h2 {
      font-size: 0.3rem;
      font-family: SourceHanSansSC;
      font-weight: 500;
      color: #2C2C2C;
      line-height: 0.5rem;
    }

    table {
      margin-top: 0.37rem;

      td {
        font-size: 0.19rem;
        font-family: SourceHanSansSC;
        font-weight: 300;
        line-height: 0.32rem;
        min-width: 1.19rem;
        vertical-align: top;
        height: 0.45rem;

        &:first-child {
          color: #6E6E6E;
        }

        &:last-child {
          color: #333333;
        }

        img {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }

    .contact-information {
      width: 100%;
      padding: 0.4rem 0.29rem 0.45rem 0.65rem;
      margin-top: 0.57rem;
      display: flex;
      justify-content: space-between;

      h2 {
        margin-top: 0.63rem;

        &:nth-of-type(2) {
          margin-top: 0.24rem;
        }
      }

      .left {

        p {
          font-size: 0.15rem;
          font-family: SourceHanSansSC;
          font-weight: 400;
          color: #2C2C2C;
          line-height: 0.25rem;
          margin-top: 0.16rem;
        }
      }

      .right {
        #container {
          width: 7.64rem;
          height: 100%;
        }
      }
    }

    .consult {
      margin-top: 0.57rem;

      h2 {
        margin-left: 0.66rem;
      }

      .consult-form {
        display: flex;
        flex: 1;
        margin-top: 0.37rem;
        justify-content: space-between;

        .form-wrap {
          padding: 0.6rem 0.66rem;
          width: 7.15rem;

          .form {
            display: grid;
            grid-template-columns: repeat(2, auto);
            grid-column-gap: 0.16rem;
            grid-row-gap: 0.33rem;

            :deep(.el-form-item__label) {
              font-size: 0.2rem;
              font-family: SourceHanSansSC;
              font-weight: 500;
              color: #2C2C2C;
              line-height: 0.34rem;
              height: 0.44rem;
              position: relative;

              &::before {
                position: absolute;
                right: -0.07rem;
                font-size: 0.26rem;
                font-family: SourceHanSansSC;
                font-weight: 300;
                color: #FF0000;
                line-height: 0.44rem;
              }
            }

            .el-form-item {
              margin: 0;
            }

            .input-wrap {
              width: 100%;
              margin-top: 0.1rem;
              display: block;

              :deep(.el-input__wrapper) {
                width: 100%;
                height: 0.39rem;
                background: rgba(101, 101, 101, 0);
                border: 0.01rem solid #C1C1C1;
                border-radius: 0.05rem;
                padding: 0 0.09rem;

                &::placeholder {
                  font-size: 0.13rem;
                  font-family: SourceHanSansSC;
                  font-weight: 300;
                  color: #595959;
                  line-height: 0.22rem;
                  opacity: 0.58;
                }
              }

              button {
                width: 100%;
                height: 0.39rem;
                background: #BC0507;
                border: 0.01rem solid #BC0507;
                border-radius: 0.05rem;
                font-size: 0.2rem;
                font-family: SourceHanSansSC;
                font-weight: 500;
                color: #FFFFFF;
              }
            }

            .btn-wrap {
              display: flex;
              align-items: flex-end;
            }
          }
        }

        .company-info {
          width: calc(100% - 7.15rem - 0.1rem);
          background-position: right top;
          background-repeat: no-repeat;
          background-size: cover;
          padding: 0.66rem 0.4rem;
          background-image: v-bind(companyInfoBg1);

          h3 {
            font-size: 0.2rem;
            font-family: SourceHanSansSC;
            font-weight: 500;
            color: #2C2C2C;
            line-height: 0.34rem;
          }

          table {
            width: 72%;

            td:first-child {
              min-width: 0.91rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #contact {
    .banner {
      height: 3.8rem;
    }

    .content-wrap {
      width: 100%;
      padding: 0;

      .contact-us {
        padding: 0.7rem 0.4rem 0.51rem;

        .card {
          border: none;
          box-shadow: none;
          display: flex;
          flex-direction: column-reverse;
          padding: 0 0.19rem;
        }

        .contact-information {
          margin-top: 0.51rem;

          .right {
            width: 100%;
            height: 4.07rem;

            #container {
              width: 100%;
              height: 100%;
            }
          }

          .left {
            table {
              td {
                font-size: 0.23rem;
              }
            }

            p {
              font-size: 0.23rem;
              line-height: 1.5;
            }
          }

          h2:nth-of-type(2) {
            margin-top: 0.44rem;
          }
        }
      }

      .consult {
        background: #F5F5F5;
        margin-top: 0;
        padding: 0.58rem 0.4rem 0.66rem;

        h2 {
          margin-top: 0;
        }

        .consult-form {
          flex-direction: column;

          .company-info {
            width: 100%;
            margin-top: 0.58rem;
            background-image: v-bind(companyInfoBg2);
            background-size: cover;
            background-repeat: no-repeat;
            padding: 0.54rem 0.53rem;

            h2 {
              margin-left: 0;
            }

            table {
              width: 100%;

              td {
                font-size: 0.24rem;

                &:first-child {
                  min-width: 1.2rem;
                }
              }
            }
          }

          .top-wrap {
            display: flex;
            justify-content: space-between;

            :deep(.el-form-item) {
              display: block;
              width: 3.1rem;

              &:last-child {
                margin-bottom: 0;
              }

              .el-form-item__label {
                width: 100%;
                font-size: 0.26rem;
                margin-bottom: 0.1rem;
              }

              .el-input__wrapper,
              .el-textarea {
                background-color: transparent;
                border: 1px solid rgba(97, 97, 97, 0.75);
                border-radius: 3px;
              }

              .el-textarea {
                height: 100%;

                .el-textarea__inner {
                  background-color: transparent;
                  height: 100%;
                }
              }

              &.leave-message {
                height: 100%;
                display: flex;
                flex-direction: column;

                .el-form-item__content {
                  flex: 1;

                  .el-input,
                  .el-input__wrapper {
                    height: 100%;
                  }
                }
              }
            }
          }

          .btn-wrap {
            margin-top: 0.24rem;
            text-align: center;

            :deep(.el-button) {
              width: 2.68rem;
              height: 0.58rem;
              border-radius: 0.2rem;
            }
          }
        }
      }

      h1 {
        text-align: left;
        padding: 0 0.1rem;
        font-size: 0.35rem;
        line-height: 0.67rem;

        &::after {
          bottom: -0.17rem;
          width: 100%;
          background: #BEBEBE;
          height: 1px;
        }
      }

      h2 {
        font-size: 0.29rem;
        font-weight: 500;
        color: #060606;
        margin-top: 0.55rem;
      }
    }
  }
}
</style>

<style lang="scss">
#container {
  .city {
    font-size: 0.17rem;
    font-family: SourceHanSansSC;
    font-weight: bold;
    color: #F54336;
    line-height: 0.29rem;
  }

  .address {
    font-size: 0.14rem;
    font-family: SourceHanSansSC;
    font-weight: 400;
    color: #2C2C2C;
    line-height: 0.23rem;
  }
}
</style>

<template>
  <LoginContainer :formState="formState" />
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent } from 'vue'

// 引入组件
const LoginContainer = defineAsyncComponent(() => import('./components/PageContainer.vue'));

const formState = ref({
  tabList: [
    { name: '注册笔熊分享账号', key: 'login-register' }
  ],
  activeKey: 'login-register'
})

</script>

<style lang="scss" scoped>
// v-deep::(.form-container) .tab-box .tab-item {

// }
:deep(.form-container-view) {
  .tab-box {
    .tab-item {
      font-size: 0.22rem !important;
      font-weight: bold;
      &.active {
        &::after {
          height: 0 !important;
        }
      }
    }
  }
}
</style>
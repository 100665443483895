<template>
  <div>
    <!-- 轮播图 -->
    <swiper v-if="bannerList.length > 0" :slides-per-view="1" :space-between="0" :speed="500"
      :loop="true" :modules="modules" :autoplay="{ delay: 3000, disableOnInteraction: false }">
      <swiper-slide v-for="(item, index) in bannerList" :key="index">
        <div class="slide-item">
          <img v-if="isPC" :src="item.image" alt="" />
          <img v-else :src="item.image_s || item.image" alt="" />
          <router-link class="link-btn" to="/pdf/index"><my-button text="笔熊分享" /></router-link>
        </div>
      </swiper-slide>
    </swiper>
    <!-- 你是否经常有这样的困扰 -->
    <BotherView />
    <!-- 用笔熊分享 -->
    <PdfView />
    <!-- 如何给PDF加密 -->
    <EncryptionView />
    <!-- 内容列表 -->
    <ContentView />
  </div>
</template>

<script setup lang="ts">
import { ref, defineAsyncComponent, onMounted } from 'vue'
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Autoplay } from "swiper";
import "swiper/css";
const modules = [Autoplay];
import { getBannerApi } from '@/api/common'
import checkPc from '@/utils/checkPc'

/**
 * 判断是否PC端
 */
const isPC = checkPc()

// 引入组件
const BotherView = defineAsyncComponent(() => import('./components/Bother.vue'));
const PdfView = defineAsyncComponent(() => import('./components/Pdf.vue'));
const EncryptionView = defineAsyncComponent(() => import('./components/Encryption.vue'));
const ContentView = defineAsyncComponent(() => import('./components/Content.vue'));

const bannerList = ref([])

// 获取首页轮播图
const getBannerList = async () => { 
  const res:any = await getBannerApi()
  bannerList.value = res
}

onMounted(() => {
  getBannerList()
})

</script>

<style lang="scss" scoped>
.slide-item {
  height: 6rem;
  position: relative;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .link-btn {
    position: absolute;
    left: 0;
    right: 0;
    bottom: .80rem;
    margin: auto;
    width: max-content;
  }
}
@media screen and (max-width: 768px) {
  .slide-item {
    height: 3.44rem;
    .link-btn {
      bottom: 0.3rem;
    }
  }
}
</style>
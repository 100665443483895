<template>
  <div class="copy-link cursor-pointer" :data-clipboard-text="text" @click="copyLink">
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import ClipboardJS from 'clipboard';
import { ElMessage } from 'element-plus'

// 定义emit
const emit = defineEmits(['successCallback', 'errorCallback'])

// 定义props
const props = defineProps({
  text: {
    type: String,
    default: ''
  }
})

/**
 * 复制链接
 */
const copyLink = () => {
  const clipboard = new ClipboardJS('.copy-link')
  clipboard.on('success', () => {
    ElMessage.success('复制成功');
    emit('successCallback');
    clipboard.destroy()
  })
  clipboard.on('error', () => {
    ElMessage.error('复制失败');
    emit('errorCallback');
    clipboard.destroy()
  })
}

</script>

<style lang="scss" scoped>

</style>
<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <div class="member-main flex">
    <!-- 左侧菜单 -->
    <div v-if="isPC" class="left-container">
      <!-- 菜单 -->
      <div class="menu-container">
        <div class="menu-box">
          <template v-for="(v, i) in menu" :key="i">
            <div
              v-if="!v.meta.hidden && !(v.meta.hiddenArr && v.meta.hiddenArr.includes(userType))"
              :class="['cursor-pointer']"
            >
              <template v-if="v.meta.showDialog">
                <Customize placement="right" :offset="60">
                  <template #default>
                    <menu-item :menu="v" />
                  </template>
                </Customize>
              </template>
              <template v-else>
                <menu-item :menu="v" />
              </template>
            </div>
          </template>
        </div>
      </div>
      <!-- 底部容量 -->
      <div class="bottom-size">
        <!-- 进度条 -->
        <div class="progress">
          <div class="progress-bar" :style="{ width: `${progress}%` }"></div>
        </div>
        <div class="size-box flex">
          <!-- 10g/30g -->
          <p class="tips">流量消耗：{{ `${flowInfo.use}/${flowInfo.zong}` }}</p>
          <div v-if="userType != 'sub_account'" class="size-btn" @click="toPayFlow">充值</div>
        </div>
      </div>
      <!-- 购买流量 -->
      <PayFlowDialog ref="payFlowRef" @paySuccess="paySuccess" />
    </div>
    <div class="right-container">
      <router-view />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted, defineAsyncComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
import { storeToRefs } from 'pinia'
import { useUserStoreHook } from "@/store/modules/user";
import Customize from '@/components/customize/Customize.vue';
import checkPc from '@/utils/checkPc'
import { useUser } from '@/composable/useUser'
import { ElMessage } from 'element-plus';
import { flowStatisticsApi } from '@/api/member/new';

// 判断是否PC端
const isPC = checkPc()
const { userType } = useUser()

// 引入组件
const MenuItem = defineAsyncComponent(() => import('./components/MenuItem.vue'))
const PayFlowDialog = defineAsyncComponent(() => import('@/components/Dialog/PayFlowDialog.vue'))


const userStore = useUserStoreHook()
const { userInfo } = storeToRefs(userStore)

const router = useRouter()
const routes = router.getRoutes()

const menu = ref<any>([])

const filterRoutes = () => {
  let arr = routes.filter(item => item.name == 'member-index')
  if (arr.length) menu.value = arr[0].children
}

const progress = computed(() => flowInfo.value.bfb || 0)

filterRoutes()

// 获取流量统计
const flowInfo = ref<any>({})
const flowStatistics = async () => {
  const result = await flowStatisticsApi()
  console.log(result, '流量统计')
  flowInfo.value = result
}

// 点击充值
const payFlowRef = ref()
const toPayFlow = () => {
  payFlowRef.value.openDialog()
}

// 支付成功
const paySuccess = () => {
  // 支付成功 更新数据
  ElMessage.success('充值成功')
  // 更新进度条
  flowStatistics()
}

onMounted(() => {
  flowStatistics()
})

</script>

<style lang="scss" scoped>
.member-main {
  height: 100%;
  background-color: #F8F9FA;
  .left-container {
    width: 2.46rem;
    height: 100%;
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 0.92rem;
    .menu-container {
      width: 100%;
      .menu-box {
        margin: 0.38rem 0 0 0rem;
        padding: 0 0.14rem;
        
      }
    }
    // 底部容量
    .bottom-size {
      width: 100%;
      padding: 0 0.18rem;
      .progress {
        width: 100%;
        height: 0.06rem;
        background: #EEEFF4;
        border-radius: 0.08rem;
        margin: 0.1rem 0 0.07rem 0;
        .progress-bar {
          height: 100%;
          background: #FF7B6D;
          border-radius: 0.06rem;
          transition: all 0.3s ease-in-out;
        }
      }
      .size-box {
        justify-content: space-between;
        .tips {
          font-size: 0.12rem;
          color: #515256;
        }
        .size-btn {
          cursor: pointer;
          height: 0.16rem;
          background: #FF7B6D;
          border-radius: 0.07rem;
          font-size: 0.1rem;
          color: #FFFFFF;
          text-align: center;
          line-height: 0.15rem;
          padding: 0 0.1rem;
        }
      }
    }
  }
  .right-container {
    flex: 1;
    height: 100%;
    overflow: hidden;
  }
}
@media screen and (max-width: 768px) {
  .left-container{
    display: none;
  }
  .member-main .right-container{
    width: 100%;
  }
}
</style>

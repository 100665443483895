import { storageLocal } from "@/utils/storage";
import { useUserStoreHook } from "@/store/modules/user";

const TokenKey = "token";
const UserKey = "user";

// 获取token
export function getToken() {
  // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
  return storageLocal.getItem("token");
}

// 设置token
export function setToken(data:any) {
  useUserStoreHook().SET_TOKEN(data);
  storageLocal.setItem(TokenKey, data);
}

// 删除token
export function removeToken() {
  if (getToken()) storageLocal.removeItem(TokenKey);
}

// 获取用户id
export function getUserId() {
  // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
  return storageLocal.getItem("user");
}

// 设置用户id
export function setUserId(data:any) {
  useUserStoreHook().SET_TOKEN(data);
  storageLocal.setItem(UserKey, data);
}

// 删除用户id
export function removeUserId() {
  if (getUserId()) storageLocal.removeItem(UserKey);
}
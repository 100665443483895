import { defineAsyncComponent, ref } from "vue";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";
import { useCheckFileOptionsAuth } from "@/utils/checkFileOptionsAuth";
import { formatTimeSelf } from "@/utils/formatDateTime";  // 时间格式化

export const useFileItem = (file: any = {}) => {
  
  const router = useRouter();
  const { checkFileOptionsAuth } = useCheckFileOptionsAuth();

  const FileRename = defineAsyncComponent(() => import('@/views/member/file/components/RenameForm.vue')) // 重命名
  const TipsForm = defineAsyncComponent(() => import("@/views/member/file/components/TipsForm.vue"))  // 下架提醒
  const CoverForm = defineAsyncComponent(() => import('@/views/member/file/components/CoverForm.vue'))  // 设置封面
  const ReplaceForm = defineAsyncComponent(() => import('@/views/member/file/components/ReplaceForm.vue'))  // 替换文件
  const SetFileForm = defineAsyncComponent(() => import("@/components/SetFileForm/index.vue"))  // 加密设置
  const CopyLinkForm = defineAsyncComponent(() => import("@/views/member/file/components/CopyLinkForm.vue")) // 复制链接

  // 预览
  const toView = async (fileData: any = null) => {
    if (!fileData) fileData = file;
    if (fileData.file_type == 2) {
      router.push({path: '/member/phone/folder', query: {id: fileData.id, name: fileData.title}});
    } else if (fileData.child_num) {
      await checkFileOptionsAuth('add_fileurl');
      router.push({path: '/member/phone/sub-link', query: {id: fileData.id, name: fileData.title}});
    }
    else if(fileData.share_url) {
      await checkFileOptionsAuth('previewbutton');
      (window as any).location.href = fileData.share_url;
    }
  }

  // 复制文本
  const copyText = async (text: string) => {
    // console.log(text, 'text');
    if (!text) return;
    const textArea = document.createElement('textarea');
    textArea.value = text;
    textArea.style.position = 'fixed';
    textArea.style.opacity = '0';
    document.body.appendChild(textArea);
    textArea.select();
    try {
      const result = document.execCommand('copy');
      if (result) {
        ElMessage.success('复制成功')
      } else {
        ElMessage.error('复制失败')
      }
    } catch (error) {
      ElMessage.error('复制失败')
    } finally {
      document.body.removeChild(textArea);
    }
  }

  const filterComponent = (key: string) => {
    switch (key) {
      case 'rename':
        return FileRename;
      case 'removeReminder':
        return TipsForm;
      case 'miniProgramCover':
        return CoverForm;
      case 'replace':
        return ReplaceForm;
      case 'set':
      case 'create_sub':
        return SetFileForm;
      case 'copy':
        return CopyLinkForm;
    }
  }

  return {
    router, toView, formatTimeSelf, copyText, filterComponent, checkFileOptionsAuth
  }
}
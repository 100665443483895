import request from '@/utils/request';

// 单页详情  id (关于我们id为1  联系我们为2  隐私策略3  使用协议4  会员协议5)
export const singlePageApi = (data: object) => {
  return request({
    url: `/Information/danye`,
    method: 'post',
    data,
  });
}

// 提交留言  name 姓名  tel 手机号 content 留言内容
export const submitMessageApi = (data: object) => {
  return request({
    url: `/Information/messageSave`,
    method: 'post',
    data,
  });
}

// 帮助中心
export const helpCenterApi = (data: object) => {
  return request({
    url: `/news/newslist`,
    method: 'post',
    data: data
  });
}

// 获取首页轮播图
export const getBannerApi = () => {
  return request({
    url: `/banner/lst`,
    method: 'get'
  });
}

// 获取友情链接
export const getFriendLinkApi = () => {
  return request({
    url: `/content/blogroll`,
    method: 'get'
  });
}

// 获取网站信息
export const getWebInfoApi = () => {
  return request({
    url: `/content/siteinfo`,
    method: 'get'
  });
}

// 获取邀请信息
export const getInviteInfoApi = () => {
  return request({
    url: `/content/yaoqing`,
    method: 'get'
  });
}

// 获取广告
export const getAdvertApi = () => {
  return request({
    url: `/content/guanggao`,
    method: 'get'
  });
}

// 获取微信分享教程链接
export const getWechatShareLinkApi = () => {
  return request({
    url: 'wxfx/index',
    method: 'get'
  })
}
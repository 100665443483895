<script setup lang="ts">
import { ref, defineAsyncComponent, onMounted } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { Delete, RefreshLeft } from "@element-plus/icons-vue";
import { fileRecycleApi, fileRecycleDelApi, fileRecycleRestoreApi } from '@/api/file'
import { useDialogOwn } from '@/composable/useDialog'

const { DialogView, dialogVisible, isPC } = useDialogOwn()
const dialogConfig = ref({
  title: '',
  tips: ''
})

// 引入组件
const MainTitle = defineAsyncComponent(() => import("@/views/member/components/MainTitle.vue"));
const PhoneListItem = defineAsyncComponent(() => import("@/views/member/recycle/PhoneListItem.vue"));
const TableSearch = defineAsyncComponent(() => import('@/components/PTable/TableSearch.vue'))

/**
 * 筛选表单
 */
const form = ref<any>({
  title: ''
})

/**
 * 文件列表处理
 */
const tableLoading = ref(false)
const tableData = ref<any>([])
const tableParams = ref({ limit: 15, page: 1, title: '' })
const tableTotal = ref(0)
// 获取文件列表
const getFileList = async () => {
  try {
    tableLoading.value = true
    const res: any = await fileRecycleApi(tableParams.value)
    // console.log(res)
    tableData.value = res.data
    tableTotal.value = res.total
  } catch (error) {
    console.log(error)
  } finally {
    tableLoading.value = false
  }
}

/* 表格数据多选处理 */
const multipleTableRef = ref()
const multipleSelection = ref([])
const handleSelectionChange = (val: any) => {
  multipleSelection.value = val
}

//  提示文字处理
const toTips = (type: string) => {
  let tips = '', api = <any>null
  switch (type) {
    case 'del':
      tips = '删除'
      api = fileRecycleDelApi
      break;
    case 'restore':
      tips = '恢复'
      api = fileRecycleRestoreApi
      break;
  }
  return { tips, api }
}

// 批量删除、恢复
const delBtn = (type: string) => {
  let data = multipleSelection.value
  let tips = toTips(type).tips
  if (data.length == 0) {
    ElMessage.error(`请选择需要${tips}的数据`)
  } else {
    // dialogConfig.value.title = `确定要${tips}数据吗?`
    // dialogConfig.value.tips = `该操作会${tips}当前数据`
    // dialogVisible.value = true
    let ids = data.map((item: any) => item.id)
    toDelete(type, ids)
  }
}

// 点了确定
const action = ref({ type: '', id: '' })
const dialogSubmit = async () => {
  try {
    const { type, id } = action.value
    let { tips, api } = toTips(type)
    await api({ id })
    ElMessage.success(`${tips}成功`)
    getFileList()
  } catch (error) {
    console.log(error)
  } finally {
    dialogVisible.value = false
  }
}

// 删除接口
const toDelete = async (type: string, ids: any, showConfirm: Boolean = true) => {
  let { tips } = toTips(type)
  if (isPC.value) {
    dialogConfig.value.title = `确定要${tips}数据吗?`
    dialogConfig.value.tips = `该操作会${tips}当前数据`
  } else {
    dialogConfig.value.title = `${tips}`
    dialogConfig.value.tips = `确定${tips}数据吗?`
  }
  dialogVisible.value = true
  action.value = { type, id: ids.toString()}
}

// 手机端删除、恢复
const toDeleteM = (e: any) => {
  const { type, id } = e
  toDelete(type, id, false)
}

// 搜索
const toSearch = () => {
  tableParams.value.page = 1;
  getFileList()
}

/**
 * 用户下拉
 */
const userOptions = ref<any[]>([]);

onMounted(() => {
  getFileList()
})
</script>

<template>
  <div id="recycle">
    <div v-if="isPC" class="form-view pc-wrap">
      <div class="table-top flex-c-between pt10 pb10">
        <div class="flex-a-center">
          <p-button class="mr10" text="批量删除" @click="delBtn('del')" />
          <p-button text="批量恢复" theme="minor" @click="delBtn('restore')" />
        </div>
        
        <table-search v-model:value="tableParams.title" @search="toSearch" />
      </div>
      <!-- 表格 -->
      <div class="table-box">
        <el-table ref="multipleTableRef" v-loading="tableLoading" :data="tableData" style="width: 100%" height="calc(100vh - 0.84rem - 200px)"
          size="default" @selection-change="handleSelectionChange" border>
          <el-table-column type="selection" :width="55" />
          <el-table-column prop="title" label="文档名称" align="left" show-overflow-tooltip>
            <template #default="{ row }">
              <div v-if="row.file_type === 2" class="flex-a-center cursor-pointer">
                <img style="width: 0.21rem;" src="@/assets/member/u002.png" class="mr10" />
                <p>{{ row.title }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="intro" label="备注" align="center">
            <template #default="scope">
              <span>{{ scope.row.intro || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="nickname" label="上传人" align="center" width="180">
            <template #default="scope">
              <span>{{ scope.row.nickname || '-' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="id" label="文档ID" align="center" width="100" />
          <el-table-column prop="deletetime" label="删除时间" :width="200" align="center" />
          <el-table-column prop="recycle_text" label="失效时间" align="center" />
          <el-table-column prop="action" label="操作" align="center" :width="200">
            <template #default="scope">
              <el-button link :icon="Delete" @click="toDelete('del', scope.row.id)">
                <span>删除</span>
              </el-button>
              <el-button link :icon="RefreshLeft" @click="toDelete('restore', scope.row.id)">
                <span>还原</span>
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
          <el-pagination background layout="total, prev, pager, next, jumper" v-model:current-page="tableParams.page"
            v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
        </div>
      </div>
    </div>
    <div v-else class="phone-wrap flex-column-center">
      <div class="list-wrap">
        <phone-list-item v-for="item in tableData" :key="item.file_id" :data="item" @toDelete="toDeleteM" />
      </div>
      <div v-if="tableTotal > 0" class="flex-c-center pt20 pb20">
        <el-pagination :hide-on-single-page="true" background layout="prev, next" v-model:current-page="tableParams.page"
          v-model:page-size="tableParams.limit" :total="tableTotal" @current-change="getFileList" />
      </div>
    </div>
    <dialog-view v-bind="dialogConfig" v-model:dialogVisible="dialogVisible" @submit="dialogSubmit" />
  </div>
</template>

<style lang="scss" scoped>
#recycle {
  height: 100%;
  overflow: auto;

  .pc-wrap {
    :deep(.el-form--inline) {
      .el-form-item {
        margin-bottom: 0;
        margin-right: 10px;

        .el-input__wrapper {
          background-color: transparent;
        }
      }
    }

    .table-top {
      :deep(.el-button) {
        color: #fff;
      }
    }

    .table-box {
      :deep(.el-button.is-link) {
        color: #06a7ff;

        img {
          margin-right: 5px;
        }
      }
    }

    .pagination {
      margin-top: 30px;

      :deep(.el-pagination) {
        justify-content: center;
      }
    }
  }

  .phone-wrap {
    background: #fff;
    padding: 0.26rem;
    padding-top: 0;
    height: 100%;
    box-sizing: border-box;
    display: flex;

    .list-wrap {
      flex: 1;
      width: 100%;
      // margin-top: 0.38rem;
      // height: 76vh;
    }

    .pagination {
      margin-top: 0.2rem;

      :deep(.el-pagination) {
        justify-content: center;
      }
    }
  }
}
</style>


<script setup lang="ts">
import { defineAsyncComponent } from 'vue'

// 引入组件
const Layout = defineAsyncComponent(() => import('@/layout/index.vue'));
const VipCompare = defineAsyncComponent(() => import("@/components/vipCompare/index.vue"));


// css所需图片
const titleImg1 = `url(${require('@/assets/member/img/member/privilege-title-icon.png')})`;
const titleImg2 = `url(${require('@/assets/member/img/member/privilege-title-icon2.png')})`;
</script>

<template>
  <layout :footer="false" :parent="false">
    <div id="privilege">
      <!-- <h1 class="title">
        <span>会员权益</span>
      </h1> -->

      <div class="table-wrap">
        <vip-compare />
      </div>
    </div>
  </layout>
</template>

<style lang="scss" scoped>
#privilege {
  background-color: #fff;
  padding-top: 50px;

  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 41px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #444141;

    &::before,
    &::after {
      display: block;
      content: '';
      width: 2.05rem;
      height: 0.24rem;
      background-size: 100% 100%;
      background-repeat: no-repeat;
    }

    &::before {
      background: v-bind(titleImg1);
    }

    &::after {
      background: v-bind(titleImg2);
    }

    span {
      margin: 0 0.43rem;
    }
  }

  .table-wrap {
    width: 76%;
    margin: 0.52rem auto 0;

    :deep(th) {
      height: 0.87rem;
      background-color: #FCF5F4;

      &:nth-last-of-type(even) {
        background-color: #FDE8E5;
      }

      &:first-of-type {
        border-radius: 0.1rem 0 0 0;
      }

      &:last-of-type {
        border-radius: 0 0.1rem 0 0;
      }
    }

    :deep(tr) {
      &:last-of-type {
        td {
          &:first-of-type {
            border-radius: 0 0 0 0.1rem;
          }

          &:last-of-type {
            border-radius: 0 0 0.1rem 0;
          }
        }
      }
    }

    :deep(td) {
      height: 0.87rem;
      font-size: 0.19rem;
      color: #565656;

      &.yes {
        color: #242424;
      }

      &.no {
        color: rgba(36, 36, 36, 0.32);
      }
    }
  }
}

@media screen and (max-width: 768px) {
  #privilege{
    padding: 0.3rem 0.14rem 0.53rem;
    .title{
      font-size: 0.34rem;
      &::before,
      &::after {
        width: 1.32rem;
        height: 0.17rem;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    .table-wrap{
      width: 100%;
      margin-top: 0;
      :deep(table){
        border-radius: 0;
        th {
          height: 0.51rem;
        }
        td {
          height: 0.55rem;
        }
        td:first-of-type{
          text-align: left;
          padding: 0 0.26rem;
        }
        th:first-of-type{
          border-radius: 0 0 0 0;
        }
        th:last-of-type{
          background: #FFD5CE;
          border-radius: 0 0.0 0 0;
        }
        tr:last-of-type{
          td:first-of-type{
            border-radius: 0 0 0 0;
          }
          td:last-of-type{
            border-radius: 0 0 0 0;
          }
        }
      }
    }
  }
}
</style>

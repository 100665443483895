<script setup lang="ts">
import {ref} from "vue";
import {queryMessageSettingApi, saveMessageSettingApi} from "@/api/message";
import {ElMessage} from "element-plus";

interface props {
  tabList: any[] // 消息设置选项卡列表
}

const props = defineProps<props>();

const {tabList} = props;

const emits = defineEmits(['close']);

/**
 * 接受方式
 */
const acceptList = [
  //   站内信不能取消，默认选中
  {value: 1, label: '站内信', name: 'znx'},
  // {value: 2, label: '短信', name: 'sms'},
  {value: 3, label: '邮件', name: 'email'},
  {value: 4, label: '公众号', name: 'wechat'},
];

/**
 * 表格数据
 */
const tableData = ref<any[]>(tabList.map(v => ({
  type: v.tips,
  typeId: v.id,
  accept: [],
  acceptList
})));

/**
 * 取消保存
 */
const cancelSave = () => {
  emits('close');
};

/**
 * 保存
 * 数据格式：[{"id": 1,"user_id": 3,"type": "1","znx": 1,"sms": 0,"email": 0,"wechat": 0}]
 */
const save = async () => {
  let data = tableData.value.map(v => {
    let accpet:any = {}
    v.acceptList.forEach((v1: any) => {
      accpet[v1.name] = v.accept.includes(v1.name) ? 1 : 0;
    })
    return {
      id: v.id,
      user_id: v.userId,
      type: v.typeId,
      ...accpet
    }
  })
  // 调用保存接口
  let res = await saveMessageSettingApi({data});
  ElMessage.success('保存成功');
  cancelSave();
};

/**
 * 获取设置列表
 */
const getSettingList = async () => {
  let data:any = await queryMessageSettingApi();
  data && data.length && data.forEach((v: any) => {
    if (+v.znx === 0){
      v.znx = 1
    }
    let item = tableData.value.find(vv => vv.typeId === v.type);
    item.id = v.id;
    item.userId = v.user_id;
    for (let vKey in v) {
      const vVal = v[vKey];
      let acceptItem = item.acceptList.filter((vv:any) => vv.name === vKey);
      if (acceptItem.length){
        acceptItem.forEach((vvv:any) => {
          if (vVal){
            item.accept.push(vvv.name)
          }
        })
      }
    }
  })
}

getSettingList();
</script>

<template>
  <div id="settings">
    <el-table :data="tableData" v-if="tableData.length" style="width: 100%" border header-row-class-name="header">
      <el-table-column prop="type" label="消息类型" width="auto"/>
      <el-table-column label="接受方式" :width="300">
        <template #default="{row}">
          <el-checkbox-group v-model="row.accept">
            <el-checkbox v-for="item in row.acceptList" :key="item.value" :label="item.name" :disabled="item.name === 'znx'">{{item.label}}</el-checkbox>
          </el-checkbox-group>
        </template>
      </el-table-column>
    </el-table>
    <div class="btn-wrap flex-c-end">
      <p-button text="取消" theme="cancel" class="mr10" @click="cancelSave" />
      <p-button text="确定" @click="save" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.btn-wrap{
  margin-top: 0.2rem;
  text-align: right;
}
:deep(.el-table .header td.el-table__cell){
  background: #F8F9FA;
  color: #000000;
}
:deep(.el-table){
  --el-table-border: 1px solid #C7C7C7;
  --el-table-border-color: #C7C7C7;
}
:deep(.el-button){
  min-width: 80px;
}
</style>

import request from '@/utils/request';
// forceLogin标志该请求是否无需鉴权，forceLogin为true，表示无需鉴权

// 注册  username、password、password_two、mobile、code
export const registerApi = (data: object) => {
	return request({
		url: '/user/register',
		method: 'post',
		data: { ...data, forceLogin: true }
	});
}

// 登录  account、password
export const loginApi = (data: object) => {
	return request({
		url: '/user/login',
		method: 'post',
		data: { ...data, forceLogin: true }
	});
}

// 退出登录
export const logoutApi = () => {
	return request({
		url: '/user/logout',
		method: 'post'
	});
}

// 获取用户信息
export const getUserInfoApi = () => {
	return request({
		url: '/user/userinfo',
		method: 'post',
	});
}

// 获取验证码  mobile、type=2:注册、3:修改手机号、4:修改密码、5:微信扫码注册绑定手机号
export const sendSmsApi = (data: object) => {
	return request({
		url: '/sendsms/index',
		method: 'post',
		data: { ...data, forceLogin: true }
	});
}

/**
 * 修改个人信息
 */
export const editUserInfoApi = (data: object) => {
	return request({
		url: '/user/profile',
		method: 'post',
		data
	});
}

/**
 * 修改密码
 */
export const editPasswordApi = (data: object) => {
	return request({
		url: '/user/resetpwd',
		method: 'post',
		data
	});
}

/**
 * 修改手机号
 */
export const editMobileApi = (data: object) => {
	return request({
		url: '/user/changemobile',
		method: 'post',
		data
	});
}

// 找回密码 验证手机号
export const findPasswordApi = (data: object) => {
	return request({
		url: '/user/changenext',
		method: 'post',
		data
	});
}

// 获取邀请链接
export const getInviteUrlApi = () => {
	return request({
		url: '/user/yqurl',
		method: 'get'
	});
}

// 注册验证验证码
export const registerVerifyCodeApi = (data: object) => {
	return request({
		url: '/user/checkcode',
		method: 'post',
		data
	});
}

// 微信扫码登录
export const wxLoginApi = () => {
	return request({
		url: '/user/wx_code',
		method: 'get'
	});
}

// 微信扫码登录回调
export const wxLoginCallbackApi = (data: object) => {
	return request({
		url: '/user/lunxun',
		method: 'post',
		data
	});
}

// 检测手机号
export const checkMobileApi = (data: object) => {
	return request({
		url: '/user/checkphone',
		method: 'post',
		data
	});
}

// 登录/注册 绑定手机号
export const bindMobileApi = (data: object) => {
	return request({
		url: '/user/bingding',
		method: 'post',
		data
	});
}

// 解绑微信
export const unbindWechatApi = () => {
	return request({
		url: '/user/delopenid',
		method: 'post'
	});
}

// 绑定微信
export const bindWechatApi = (data: object) => {
	return request({
		url: '/user/bindwechat',
		method: 'post',
		data
	});
}

// 微信登录
export const weChatLoginApi = (data: object) => {
	return request({
		url: '/wx/wx',
		method: 'post',
		data
	});
}

<template>
  <div class="form-container p32 flex-y h100">
    <div class="flex-a-center mb10">
      <p-button text="新建表单" @click="handleAdd" />
    </div>
    <p-table ref="pTableRef" v-bind="tableConfig" @edit="handleEdit" />
    <!-- 新增修改弹窗 -->
    <edit-data ref="editDataRef" @success="handelSuccess" />
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, defineAsyncComponent } from 'vue'
import { getFormListApi, deleteFormApi } from '@/api/other'

// 引入组件
const PTable = defineAsyncComponent(() => import('@/components/PTable/index.vue'))
const EditData = defineAsyncComponent(() => import('./components/EditData.vue'))

const tableConfig = reactive({
  api: getFormListApi,
  delApi: deleteFormApi,
  columns: [
    { prop: 'title', label: '表单名称' },
    { prop: 'status', label: '状态', type: 'status' },
    { prop: 'fw', label: '生效范围' },
    { prop: 'sort', label: '排序' },
  ],
  data: []
})

const pTableRef = ref()
const editDataRef = ref()

// 新建二维码
const handleAdd = () => {
  editDataRef.value.openDialog({})
}

// 编辑
const handleEdit = (row: any) => {
  editDataRef.value.openDialog(row)
}

// 新增/修改成功
const handelSuccess = () => {
  pTableRef.value.refresh()
}

</script>

<style scoped lang="scss">

</style>
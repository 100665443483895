import request from '@/utils/request';
// forceLogin标志该请求是否无需鉴权，forceLogin为true，表示无需鉴权

// 会员套餐
export const getPackageApi = () => {
    return request({
        url: '/vip/lst',
        method: 'get'
    });
};

// 会员购买生成订单接口
export const buyPackageApi = (data: any) => {
    return request({
        url: '/vip/buy',
        method: 'post',
        data
    })
}

// 微信支付接口
export const wxPayApi = (data: any) => {
    return request({
        url: '/pay/wxpay',
        method: 'post',
        data
    })
}

// 支付宝支付接口
export const aliPayApi = (data: any) => {
    return request({
        url: '/pay/alipay',
        method: 'post',
        data
    })
}

// 订单状态查询
export const orderStatusApi = (data: any) => {
    return request({
        url: '/vip/orderinfo',
        method: 'post',
        data
    })
}

// 最近购买会员列表
export const recentBuyApi = () => {
    return request({
        url: '/vip/newbuy',
        method: 'get'
    })
}

// 会员与非会员的权益
export const vipEquityApi = () => {
    return request({
        url: '/Information/userEquity',
        method: 'get'
    })
}

// 会员特有权益
export const vipSpecialEquityApi = () => {
    return request({
        url: '/Information/specific',
        method: 'get'
    })
}

// 您拥有的VIP特权
export const vipPrivilegeApi = () => {
    return request({
        url: '/Information/exclusive',
        method: 'get'
    })
}

// vip消费记录
export const vipConsumptionRecordApi = () => {
    return request({
        url: '/vip/mybuy',
        method: 'get'
    })
}

/**
 * 获取地址信息
 */
export const getAddressApi = (params: any) => {
    return request({
        url: 'ip/getaddress',
        method: 'get',
        params
    })
}

/**
 * 文件列表获取操作权限
 */
export const getFileOptionsAuth = (params: any) => {
    return request({
        url: 'equity/info',
        method: 'get',
        params
    })
}


import { ref, nextTick } from "vue";

export default function () {
  /**
 * 判断是否PC端
 */
  const isPC = ref<boolean | undefined>(undefined);

  const checkType = () => {
    isPC.value = window.innerWidth > 768;
  }

  nextTick(() => {
    checkType();
    window.addEventListener('resize', checkType);
  })

  return isPC;
}


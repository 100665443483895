// 弹窗处理
import { ref, defineAsyncComponent } from 'vue';
import checkPc from '@/utils/checkPc'

export function useDialog() {
	const dialogVisible = ref(false)
	const dialogData = ref()

	// 打开弹窗
	const openDialog = (item: any) => {
		dialogData.value = item
		dialogVisible.value = true
	}

	// 关闭弹窗
	const closeDialog = () => {
		dialogVisible.value = false
	}

	return { dialogVisible, dialogData, openDialog, closeDialog }
}


export const useDialogOwn = () => {
	
	const DialogView = defineAsyncComponent(() => import('@/components/Dialog/index.vue'))
	const MobileDialogView = defineAsyncComponent(() => import('@/components/Dialog/Mobile.vue'))

	const dialogVisible = ref(false)
	const dialogData = ref()

	const isPC = checkPc()

	// 打开弹窗
	const openDialog = (item: any = null) => {
		if (item) dialogData.value = item
		dialogVisible.value = true
	}

	// 关闭弹窗
	const closeDialog = () => {
		dialogVisible.value = false
	}

	return { DialogView, dialogVisible, dialogData, openDialog, closeDialog, isPC, MobileDialogView }
}
import { defineStore } from "pinia";

export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    showBindWeChat: false, // 是否展示过绑定微信
    hasBottomAdv: false, // 是否有底部广告
  }),
  actions: {
    SET_SHOW_BIND_WE_CHAT(val: boolean) {
      this.showBindWeChat = val;
    },
    SET_HAS_BOTTOM_ADV(hasBottomAdv: any) {
      this.hasBottomAdv = hasBottomAdv;
    },
  }
})